import {BrowserRouter, Routes,Route, useLocation} from 'react-router-dom';
import React, { useEffect } from 'react'
import Home from './pages/Home';
import Services from './pages/Services';
import Technologies from './pages/Technologies';
import Projects from './pages/Projects';
import About from './pages/About';
import Careers from './pages/Careers';
import Contact from './pages/Contact';
import Privacy from './pages/PrivacyPolicy';
import Terms from './pages/TermsOfUse';
import ThankYou from './pages/ThankYou';
import Error404 from './pages/Error404';

function App() {


  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/services' element={<Services/>}/>
        <Route path='/technologies' element={<Technologies/>}/>
        <Route path='/projects' element={<Projects/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/careers' element={<Careers/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/terms-of-use' element={<Terms/>}/>
        <Route path='/privacy-policy' element={<Privacy/>}/>
        <Route path='/thanks' element={<ThankYou/>}/>
        <Route path='/*' element={<Error404/>}/>
        
      </Routes>
    </BrowserRouter>
  );
}

export default App;
