import NavBar from '../components/NavBar'
import ThankYouExpertise from '../components/thankyou/ThankYouExpertise';
import LinkFooterTheme from '../components/footer/LinkFooterTheme'
import ThanksHero from '../components/thankyou/ThanksHero'

function thanks() {
  return (
    <div>
        <NavBar/>
        <ThanksHero/>
        <ThankYouExpertise/>
        <LinkFooterTheme/>
    </div>
  )
}

export default thanks