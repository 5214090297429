import "../../styles/About.css";
import AboutStrings from "../../utils/strings/AboutStrings";

function QuoteOne() {
  return (

    <div className="tempdiv">
    <div className="twoquote d-flex justify-content-evenly py-4 py-md-5 px-4 px-md-5" style={{backgroundColor:"#FFDAA5"}}>
        <div className="q1 col-6 me-5">
            <div className="quote-box" style={{backgroundColor:"white", borderRadius:"40px",border:"2px solid #01C9BD", Image:"linear-gradient(to right, #f1b1b1 , #82e6e8)"}}>
              <div className="row mt-lg-5 mt-md-4 mt-3 ms-3">
                <div className="col-md-1 col-2 text-end"><img className="right-quote" src="./assets/aboutimages/upper-quotation.png" alt="Quote" srcset=""  /></div>
                  <div className="col-md-10 col-8">
                  <span className="quote ms-1">{AboutStrings.quoteSection.quoteTwo} <img className="lower-quote" src="./assets/aboutimages/lower-quotation.png" alt="Quote" srcset="" /></span>
                  </div>
                <div className="col-md-1 col-2 row lower-quote align-items-end"> </div>
              </div>

              <div className="row pb-2">
                  <div className="col-md-1 col-2"></div>
                  <div className="col-md-10 col-8 mx-3 mt-3 mt-lg-5">
                    <div className="quote-name">{AboutStrings.quoteSection.nameTwo}</div>
                    <div className="quote-position">{AboutStrings.quoteSection.positionTwo}</div>
                  </div>
                  <div className="col-md-1 col-2"></div>
              </div>
            </div>
        </div>

        <div className="q2 col-6">
            <div className="d-flex flex-column quote-box" style={{backgroundColor:"white", borderRadius:"40px",border:"2px solid #01C9BD", Image:"linear-gradient(to right, #f1b1b1 , #82e6e8)"}}>
              <div className="row mt-lg-5 mt-md-4 mt-3 ms-3">
                <div className="col-md-1 col-2 text-end "><img className="right-quote" src="./assets/aboutimages/upper-quotation.png" alt="Quote" srcset=""  /></div>
                  <div className="col-md-10 col-8 ">
                  <span className="quote ms-1">{AboutStrings.quoteSection.quoteThree} <img className="lower-quote" src="./assets/aboutimages/lower-quotation.png" alt="Quote" srcset="" /></span>
                  </div>
                <div className="col-md-1 col-2 row lower-quote align-items-end"> </div>
              </div>

              <div className="row mt-auto pb-2">
                  <div className="col-md-1 col-2"></div>
                  <div className="col-md-10 col-8 mx-3 mt-3 mt-lg-5">
                    <div className="quote-name">{AboutStrings.quoteSection.nameThree}</div>
                    <div className="quote-position">{AboutStrings.quoteSection.positionThree}</div>
                  </div>
                  <div className="col-md-1 col-2"></div>
              </div>
            </div>
        </div>
</div>
</div>
  )
}

export default QuoteOne

// mx-1 mx-lg-5 py-3 py-lg-5