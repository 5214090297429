import React, {useState} from 'react'
import "../../styles/Hero.css";
import "@fontsource/open-sans"; // Defaults to weight 400.
import Strings from "../../utils/strings/HomeStrings";

export default function OurWork() {
    return (
    <>
      <div className="py-2" style={{ backgroundColor: "#FFE0B3" }}>
        <div className="container-fluid  px-4 px-md-5 ourwork">
          <div className="row pt-4 pb-4 pt-lg-5 pb-lg-4 pb-md-4">
            <div className="col-6 align-self-end">
              <h3 className="headcss">Our Work</h3>
            </div>

            <div className="col-6 viewprojbut">
              <div className="d-flex justify-content-end">
                <a href="/projects">
                  <button
                    type="button"
                    className="btn btn-primary btn-lg viewproj remove-default-bg"
                  >
                    {" "}
                    View All Projects{" "}
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row pb-4 container-fluid">
          {/* <div className="col-12" style={{ backgroundColor: "#FFE0B3" }}> */}
            <div
              className="d-flex scrollerproj pb-5"
              style={{ overflowX: "scroll" }}
            >
              {Strings.homeProj.map((item, index) => (
                <div
                  className="workbox d-flex flex-column text-center col-5 me-3 me-lg-4"
                  style={{ backgroundColor: item.boxcolor }}
                >
                  <div className="pt-3 pt-lg-3 fw-bold"> {item.name} </div>

                  <div className="projimg pt-3">
                    <img
                      src={item.image}
                      className="img-fluid"
                      alt={item.name}
                      width={"300px"}
                    />
                  </div>

                  <div className="mt-auto mb-3 projbut">
                    <a href={item.link}>
                      <button
                        type="button"
                        style={{ backgroundColor: item.button }}
                        className="btn projbutton"
                      >
                        Read More
                      </button>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          

          <div className="text-end">
            <img
              src="./assets/servicesimages/slider-arrow3.png"
              alt="Slider"
              className="slider-arrow-home"
            />
          </div>

          <div className="col-12 viewprojjugaad pt-4">
            <div className="d-flex justify-content-center">
              <a href="/projects">
                <button type="button" className="btn btn-primary viewproj remove-default-bg">
                  {" "}
                  View All Projects{" "}
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
