import React, { useState } from "react";
import "../../styles/Hero.css";
import "@fontsource/open-sans"; // Defaults to weight 400.
import Strings from "../../utils/strings/HomeStrings";

import HeroBanner from "./HeroBanner";
import WhyDoWeDo from "./WhyDoWeDo";
import OurAreas from "./OurAreas";
import TechWeWork from "./TechWeWork";
import OurWork from "./OurWork";
import AboutHumans from "./AboutHumans";
import JobOpen from "./JobOpen";

export default function Herosection() {

  return (
    <>
      <HeroBanner/>
      <WhyDoWeDo/>
      <OurAreas/>
      <TechWeWork/>
      <OurWork/>
      <AboutHumans/>
      <JobOpen/>
    </>
  );
}