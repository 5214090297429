var heroTitle = [
    {title1:"We Are Your Trusted Partner For",
    title2:"Creative Digital Transformation"
    }
]

var allContent = [
    {
      flag:false,
      id:"ui-ux",
      head: "UI/UX Design",
      content:
        "Growth becomes a lot easier when users like your brand and prefer it even. We help our clients design user interface (UI), user experience (UX), and graphics that will please, rather than frustrate users. We want our clients to be preferred by their users, not avoided. We help our clients to design outstanding UI/UX & Graphics across every channel of engagement.",
      tablets: [
        {
          link: "./assets/servicesimages/icons/uiUx/uiUxResearch.png",
          color: "#4CC0AD",
          text: "UI/UX Research",
        },
        {
          link: "./assets/servicesimages/icons/uiUx/uiUxStrategy.png",
          color: "#082340",
          text: "UI/UX Strategy",
        },
        {
          link: "./assets/servicesimages/icons/uiUx/uiUxMapping.png",
          color: "#F58C76",
          text: "UI/UX Mapping",
        },
       
        {
          link: "./assets/servicesimages/icons/uiUx/uiUxWireframing.png",
          color: "#7C4934",
          text: "Wireframing",
        },
        {
          link: "./assets/servicesimages/icons/uiUx/prototyping.png",
          color: "#F15B40",
          text: "Prototyping",
        },
        {
          link: "./assets/servicesimages/icons/uiUx/websitedesign.png",
          color: "#F68B28",
          text: "Website Design",
        },
        {
          link: "./assets/servicesimages/icons/uiUx/application.png",
          color: "#5F8AC7",
          text: "Web Application Design",
        },
        {
          link: "./assets/servicesimages/icons/uiUx/uiUxMobileApp.png",
          color: "#00A692",
          text: "Mobile App Design",
        },
        {
          link: "./assets/servicesimages/icons/uiUx/game.png",
          color: "#9FC131",
          text: "Mobile Game Design",
        },
        {
          link: "./assets/servicesimages/icons/uiUx/softwareApplication.png",
          color: "#E15858",
          text: "Software Application Design",
        },
        {
          link: "./assets/servicesimages/icons/uiUx/productDesign.png",
          color: "#0099DD",
          text: "Product Design",
        },
        {
          link: "./assets/servicesimages/icons/uiUx/graphics.png",
          color: "#646464",
          text: "Graphics Design",
        },
        {
          link: "./assets/servicesimages/icons/uiUx/illustration.png",
          color: "#026E81",
          text: "Illustration Design",
        },
        {
          link: "./assets/servicesimages/icons/uiUx/animation.png",
          color: "#8C1F28",
          text: "Animation Design",
        },
        {
          link: "./assets/servicesimages/icons/uiUx/uiUxLogoBrand.png",
          color: "#00ABBD",
          text: "Logo & Brand Identity Design",
        },
      ],
    },
    {
      flag:true,
      id:"web-dev",
      head: "Website Development",
      content:
        "Websites must win the hearts of users, must win the fight for irrational preference, and must be genuinely worthy of a user's time and money. Our website development methodology ensures our clients win this battle, by providing clarity on what precisely needs to be done, and how to create a website experience to be proud of. Our website development experiences are high-performing, feature-packed, digitally transformative, user-friendly, search engine friendly, responsive design, fully functional, very secure, and able to scale as your enterprise grows.",
      tablets: [
        {
          link: "./assets/servicesimages/icons/websiteDevelopment/cms.png",
          color: "#4CC0AD",
          text: "CMS Website Development",
        },
        {
          link: "./assets/servicesimages/icons/websiteDevelopment/wordpress.png",
          color: "#082340",
          text: "WordPress Website Development",
        },
        {
          link: "./assets/servicesimages/icons/websiteDevelopment/elementor.png",
          color: "#F58C76",
          text: "Elementor Website Development",
        },
        {
          link: "./assets/servicesimages/icons/websiteDevelopment/divi_website_development.png",
          color: "#7C4934",
          text: "Divi Website Development",
        },
        {
          link: "./assets/servicesimages/icons/websiteDevelopment/wpbakery.png",
          color: "#F15B40",
          text: "WPBakery Website Development",
        },
        {
          link: "./assets/servicesimages/icons/websiteDevelopment/drupal.png",
          color: "#F68B28",
          text: "Drupal Development",
        },
        {
          link: "./assets/servicesimages/icons/websiteDevelopment/joomla.png",
          color: "#5F8AC7",
          text: "Joomla Development",
        },
        {
          link: "./assets/servicesimages/icons/websiteDevelopment/dotnetnuke.png",
          color: "#00A692",
          text: "DotNetNuke Development",
        },
        {
          link: "./assets/servicesimages/icons/websiteDevelopment/kentico.png",
          color: "#9FC131",
          text: "Kentico Development",
        },
        {
          link: "./assets/servicesimages/icons/websiteDevelopment/umbraco.png",
          color: "#E15858",
          text: "Umbraco Development",
        },
        {
          link: "./assets/servicesimages/icons/websiteDevelopment/sitecore.png",
          color: "#0099DD",
          text: "Sitecore Development",
        },
        {
          link: "./assets/servicesimages/icons/websiteDevelopment/dotcms.png",
          color: "#646464",
          text: "dotCMS Development",
        },
        {
          link: "./assets/servicesimages/icons/websiteDevelopment/hubspot_developer.png",
          color: "#026E81",
          text: "HubSpot Development",
        },
        {
          link: "./assets/servicesimages/icons/websiteDevelopment/webflow.png",
          color: "#8C1F28",
          text: "Webflow Development",
        },
        {
          link: "./assets/servicesimages/icons/websiteDevelopment/wix.png",
          color: "#00ABBD",
          text: "Wix Development",
        },
        {
          link: "./assets/servicesimages/icons/websiteDevelopment/squarespace.png",
          color: "#32286F",
          text: "Squarespace Development",
        },
        {
          link: "./assets/servicesimages/icons/websiteDevelopment/webportal.png",
          color: "#AA570B",
          text: "Web Portal Development",
        },
        {
          link: "./assets/servicesimages/icons/websiteDevelopment/liferayportal.png",
          color: "#8C1F28",
          text: "Liferay Portal Development",
        },
        {
          link: "./assets/servicesimages/icons/websiteDevelopment/customer_website.png",
          color: "#B4531C",
          text: "Custom Web Development",
        },
        {
          link: "./assets/servicesimages/icons/websiteDevelopment/webapplication.png",
          color: "#082340",
          text: "Web Application Development",
        },
        {
          link: "./assets/servicesimages/icons/websiteDevelopment/ProgressiveWebApplicationDevelopment.png",
          color: "#44803F",
          text: "Progressive Web Application Development",
        },
      ],
    },
    {
      flag:false,
      id:"ecom-dev",
      head: "Ecommerce Development",
      content:
        "Our eCommerce offering is an end-to-end e-commerce website design and development that brings clarity, tech know-how, and creative inspiration to help organizations deliver winning commerce capabilities across digital channels like an e-commerce website, online marketplace, online retail, D2C, social shopping, and Livestream shopping. We help ambitious brands execute seamlessly and profitably across all digital channels. We do this through an integrated suite of e-commerce services from eCommerce strategy, e-commerce experience design, eCommerce technology, and marketplace to marketing.",
      tablets: [
        {
          link: "./assets/servicesimages/icons/EcomDev/woocommerce.png",
          color: "#4CC0AD",
          text: "WooCommerce Development",
        },
        {
          link: "./assets/servicesimages/icons/EcomDev/shopify.png",
          color: "#082340",
          text: "Shopify Development",
        },
        {
          link: "./assets/servicesimages/icons/EcomDev/magento.png",
          color: "#F58C76",
          text: "Magento Development",
        },
        {
          link: "./assets/servicesimages/icons/EcomDev/big-commerce.png",
          color: "#7C4934",
          text: "BigCommerce Development",
        },
        {
          link: "./assets/servicesimages/icons/EcomDev/marketplace.png",
          color: "#F15B40",
          text: "Online Marketplace",
        },
        {
          link: "./assets/servicesimages/icons/EcomDev/ecom-web.png",
          color: "#F68B28",
          text: "Ecommerce Website Design & Development",
        },
      ],
    },
    {
      flag:true,
      id:"mob-app-dev",
      head: "Mobile App Development",
      content:
        "We deliver high-profile, can't fail mobile apps for a variety of clients and industries. With a focus on mobile apps for smartphones and tablets, native mobile app development is our specialty while we also have in-depth experience with newer, cross-platform technologies such as React Native and Flutter. We help our clients engage with their consumers not just on mobile but through connected devices including Apple TV, Android TV, Roku, Xbox, LG, and Samsung TVs as well as voice devices (think Alexa and Google Home) and wearables. With our in-house mobile app developers, we also develop products and prototypes for all other emerging and innovative technologies. We have stayed on the cusp of the technology industry to help brands deliver innovative mobile app experiences to their customers.",
      tablets: [
        {
          link: "./assets/servicesimages/icons/MobileAppDevelopment/ios.png",
          color: "#4CC0AD",
          text: "iOS Development",
        },
        {
          link: "./assets/servicesimages/icons/MobileAppDevelopment/android.png",
          color: "#082340",
          text: "Android Development",
        },
        {
          link: "./assets/servicesimages/icons/MobileAppDevelopment/react_native.png",
          color: "#F58C76",
          text: "React Native Development",
        },
        {
          link: "./assets/servicesimages/icons/MobileAppDevelopment/flutter.png",
          color: "#7C4934",
          text: "Flutter Development",
        },
        {
          link: "./assets/servicesimages/icons/MobileAppDevelopment/xamarin.png",
          color: "#F15B40",
          text: "Xamarin Development",
        },
        {
          link: "./assets/servicesimages/icons/MobileAppDevelopment/ionic.png",
          color: "#F68B28",
          text: "Ionic Development",
        }
      ],
    },
    {
      flag:false,
      id:"mob-game-dev",
      head: "Mobile Game Development",
      content:
        "The field of competitive video gaming is turning toward mobile games as its platform of choice. And that's the reason, our mobile game developers create great mobile games for clients that as many people as possible play for years and that are remembered forever. We have experience taking native and cross-platform mobile game development from idea to production. We offer extraordinary mobile game development services that focus on gameplay, intuitive characters, breathtaking levels, and genre-specific games. We develop all kinds of mobile games in 2D and 3D as well that match the client's requirements.",
      tablets: [
        {
          link: "./assets/servicesimages/icons/MobileGameDevelopment/unity.png",
          color: "#4CC0AD",
          text: "Unity Game Development",
        },
        {
          link: "./assets/servicesimages/icons/MobileGameDevelopment/unreal-engine.png",
          color: "#082340",
          text: "Unreal Game Development",
        },
        {
          link: "./assets/servicesimages/icons/MobileGameDevelopment/ios.png",
          color: "#F58C76",
          text: "iOS Game Development",
        },
        {
          link: "./assets/servicesimages/icons/MobileGameDevelopment/androidgamedev.png",
          color: "#7C4934",
          text: "Android Game Development",
        },
        {
          link: "./assets/servicesimages/icons/MobileGameDevelopment/game-controller.png",
          color: "#F15B40",
          text: "2D & 3D Game Development",
        },
        {
          link: "./assets/servicesimages/icons/MobileGameDevelopment/ARVR.png",
          color: "#F68B28",
          text: "AR & VR Game Development",
        },
        {
          link: "./assets/servicesimages/icons/MobileGameDevelopment/html.png",
          color: "#5F8AC7",
          text: "HTML5 Game Development",
        }
      ],
    },
    {
      flag:true,
      id:"app-prod-dev",
      head: "Application and Product Development",
      content:
        "We offer a wide range of end-to-end custom software application & product development services specifically designed to help you meet your goals, taking into account your business infrastructure and key facets of your industry. Whether it comes to customizing a running platform, developing prototype applications for a small business, or implementing complex technology solutions for enterprises, our software developers are ready to get on it. Our custom software application & product development capabilities mean we can produce bespoke solutions that solve real problems.",
      tablets: [
        {
          link: "./assets/servicesimages/icons/applicationProductDevelopment/erp.png",
          color: "#4CC0AD",
          text: "Enterprise Resource Planning (ERP)",
        },
        {
          link: "./assets/servicesimages/icons/applicationProductDevelopment/crm.png",
          color: "#082340",
          text: "Customer Relationship Management (CRM)",
        },
        {
          link: "./assets/servicesimages/icons/applicationProductDevelopment/cms.png",
          color: "#F58C76",
          text: "Content Management System (CMS)",
        },
        {
          link: "./assets/servicesimages/icons/applicationProductDevelopment/projmgmt.png",
          color: "#7C4934",
          text: "Project Management Software (PMS)",
        },
        {
          link: "./assets/servicesimages/icons/applicationProductDevelopment/docmgmt.png",
          color: "#F15B40",
          text: "Document Management System (DMS)",
        },
        {
          link: "./assets/servicesimages/icons/applicationProductDevelopment/bpm.png",
          color: "#F68B28",
          text: "Business Processs Management (BPM)",
        },
        {
          link: "./assets/servicesimages/icons/applicationProductDevelopment/POS.png",
          color: "#5F8AC7",
          text: "Point of Sale (POS)",
        },
        {
          link: "./assets/servicesimages/icons/applicationProductDevelopment/prosoft.png",
          color: "#00A692",
          text: "Procurement Software",
        },
        {
          link: "./assets/servicesimages/icons/applicationProductDevelopment/reporting_software.png",
          color: "#9FC131",
          text: "Reporting Software",
        },
        {
          link: "./assets/servicesimages/icons/applicationProductDevelopment/dashboard.png",
          color: "#E15858",
          text: "Dashboard",
        },
        {
          link: "./assets/servicesimages/icons/applicationProductDevelopment/ESA.png",
          color: "#0099DD",
          text: "Enterprise Software Application (ESA)",
        },
      ],
    },
    {
      flag:false,
      id:"front-dev",
      head: "Frontend Development",
      content:
        "We go way beyond the typical coding approach as we elevate your experience to the next level with our best front-end web development services. We heighten the user experience by crafting designs that translate into big ideas and valuable information using the latest technologies to inspire people. Our front-end web developers have experience with the latest libraries and frameworks for front-end web development. Whether you engage us to build a custom website, content management system website, e-commerce website, web portal, web application, progressive web application, or single-page application - our main goal is to make front-end web development intuitive, engaging, and user-friendly at all levels.",
      tablets: [
        {
          link: "./assets/servicesimages/icons/Frontend/html.png",
          color: "#4CC0AD",
          text: "HTML",
        },
        {
          link: "./assets/servicesimages/icons/Frontend/css.png",
          color: "#082340",
          text: "CSS",
        },
        {
          link: "./assets/servicesimages/icons/Frontend/bootstrap.png",
          color: "#F58C76",
          text: "Bootstrap",
        },
        {
          link: "./assets/servicesimages/icons/Frontend/js.png",
          color: "#7C4934",
          text: "JavaScript",
        },
        {
          link: "./assets/servicesimages/icons/Frontend/jquery.png",
          color: "#F15B40",
          text: "jQuery ",
        },
        {
          link: "./assets/servicesimages/icons/Frontend/react.png",
          color: "#F68B28",
          text: "React",
        },
        {
          link: "./assets/servicesimages/icons/Frontend/angular.png",
          color: "#5F8AC7",
          text: "Angular",
        },
        {
          link: "./assets/servicesimages/icons/Frontend/vue.png",
          color: "#00A692",
          text: "Vue.js",
        },
        {
          link: "./assets/servicesimages/icons/Frontend/typescript.png",
          color: "#9FC131",
          text: "TypeScript",
        },
        {
          link: "./assets/servicesimages/icons/Frontend/backbone.png",
          color: "#E15858",
          text: "Backbone.js",
        }
      ],
    },
    {
      flag:true,
      id:"back-dev",
      head: "Backend Development",
      content:
        "To control what happens on the server side, a rich backend is essential. It facilitates constant sending and receiving of inputs through the frontend and helps in processing data for balancing the action end. Our backend developers have proven track records in building, optimizing and deploying server-side codes, drafting complex functional protocols, constructing libraries and other components to maintain architectural consistency and outlining an organizational structure for improving the performance of your website and mobile apps. We build complex backend layers, enable cloud integration and enable smart digital transformation to strengthen your core IT landscape with our backend development services. We are experienced in website backend development, web application backend development, mobile app backend development, mobile game backend development, database, cloud migration, and integration of third-party application programming interfaces (APIs).",
      tablets: [
        {
          link: "./assets/servicesimages/icons/backend/dotnet.png",
          color: "#4CC0AD",
          text: "Microsoft .NET",
        },
        {
          link: "./assets/servicesimages/icons/backend/java.png",
          color: "#082340",
          text: "Java",
        },
        {
          link: "./assets/servicesimages/icons/backend/php.png",
          color: "#F58C76",
          text: "PHP (Laravel)",
        },
        {
          link: "./assets/servicesimages/icons/backend/python.png",
          color: "#7C4934",
          text: "Python (Django, Flask)",
        },
        {
          link: "./assets/servicesimages/icons/backend/golang.png",
          color: "#F15B40",
          text: "Golang",
        },
        {
          link: "./assets/servicesimages/icons/backend/rubyonrails.png",
          color: "#F68B28",
          text: "Ruby on Rails",
        },
        {
          link: "./assets/servicesimages/icons/backend/javascript.png",
          color: "#5F8AC7",
          text: "JavaScript (Node.js, Express.js, Meteor.js)",
        }
      ],
    },
    { 
      flag:false,
      id:"full-dev",
      head: "Full Stack Development",
      content:
        "Empowering the advancement of frontend (client-side) and backend (server-side) parts of the application, we serve the high-end full-stack development services. Our full-stack developers are capable of front-end and back-end advancements. They are additionally gifted in different information bases, facilitating, and can deal with the whole application foundation. We enable the headway of both the frontend and backend parts of an application. The dazzling thing about a full-stack development progression bunch is that you don’t need to select experts for every advancement. We develop innovative and comprehensive solutions with the most modern and diverse full-stack development technologies.",
      tablets: [
        {
          link: "./assets/servicesimages/icons/fullstack/mean.png",
          color: "#4CC0AD",
          text: "MEAN Stack",
        },
        {
          link: "./assets/servicesimages/icons/fullstack/mern.png",
          color: "#082340",
          text: "MERN Stack",
        },
        {
          link: "./assets/servicesimages/icons/fullstack/mevn.png",
          color: "#F58C76",
          text: "MEVN Stack",
        },
        {
          link: "./assets/servicesimages/icons/fullstack/lamp.png",
          color: "#7C4934",
          text: " LAMP Stack",
        },
        {
          link: "./assets/servicesimages/icons/fullstack/jamstack.png",
          color: "#F15B40",
          text: "Jamstack",
        },
      ],
    },
    { 
      flag:true,
      id:"cloud-comp",
      head: "Cloud Computing",
      content:
        "Our cloud computing services help organizations drive innovation and business transformation by increasing business agility, lowering costs, and reducing IT complexity. Our cloud computing engineers focus on reducing risk and decreasing the total cost of ownership by combining infrastructure management services with secure cloud practices. Our cloud services enable businesses to fast-track their cloud adoption across a range of client needs, simplifying orchestration and accelerating modernization at scale. Our cloud services include the three main types of cloud computing such as Infrastructure as a Service (IaaS), Platform as a Service (PaaS), and Software as a Service (SaaS).",
      tablets: [
        {
          link: "./assets/servicesimages/icons/cloudcomputing/Cloud_Strategy.png",
          color: "#4CC0AD",
          text: "Cloud Strategy",
        },
        {
          link: "./assets/servicesimages/icons/cloudcomputing/cloudconsulting.png",
          color: "#082340",
          text: "Cloud Consulting",
        },
        {
          link: "./assets/servicesimages/icons/cloudcomputing/migration.png",
          color: "#F58C76",
          text: "Cloud Migration",
        },
        {
          link: "./assets/servicesimages/icons/cloudcomputing/cloud_management.png",
          color: "#7C4934",
          text: "Cloud Management",
        },
        {
          link: "./assets/servicesimages/icons/cloudcomputing/cloud_optimization.png",
          color: "#F15B40",
          text: "Cloud Optimization",
        },
        {
          link: "./assets/servicesimages/icons/cloudcomputing/Cloud_Security.png",
          color: "#F68B28",
          text: "Cloud Security",
        },
        {
          link: "./assets/servicesimages/icons/cloudcomputing/Cloud_Native_Computing.png",
          color: "#5F8AC7",
          text: "Cloud Native Computing",
        },
        {
          link: "./assets/servicesimages/icons/cloudcomputing/cloudappdev.png",
          color: "#00A692",
          text: "Cloud Application Development",
        }
      ],
    },
    { 
      flag:false,
      id:"devops",
      head: "DevOps",
      content:
        "Experience faster delivery speeds and a stable, reliable environment with enhanced collaboration between software development (Dev) and IT operations (Ops). By embracing our DevOps consulting services, your organization can make simple processes programmable and dynamic, helping to maximize operational predictability, efficiency, security, and maintainability. We offer unique differentiators to support you, as you get ready to embark on your DevOps journey with best practices.",
      tablets: [
        {
          link: "./assets/servicesimages/icons/DevOps/contiinteg.png",
          color: "#4CC0AD",
          text: "Continuous Integration (CI)",
        },
        {
          link: "./assets/servicesimages/icons/DevOps/contidelivery.png",
          color: "#082340",
          text: "Continuous Delivery (CD)",
        },
        {
          link: "./assets/servicesimages/icons/DevOps/microservices.png",
          color: "#F58C76",
          text: "Microservices",
        },
        {
          link: "./assets/servicesimages/icons/DevOps/infrastructure.png",
          color: "#7C4934",
          text: "Infrastructure as Code (IaC)",
        },
        {
          link: "./assets/servicesimages/icons/DevOps/monitoring_logging.png",
          color: "#F15B40",
          text: "Monitoring and Logging",
        },
        {
          link: "./assets/servicesimages/icons/DevOps/comm_collaboration.png",
          color: "#F68B28",
          text: "Communication and Collaboration",
        }
      ],
    },
    { 
      flag:true,
      id:"qa-test",
      head: "QA and Software Testing",
      content:
        "We work with the best software testing practices and the latest technology to guarantee the best results for our clients. No matter the size or complexity of the project, our software testing engineers provide superior QA testing services through a deep understanding of your business goals and applications. Our software quality assurance services save costs while enhancing speed, quality, security, and experience.",
      tablets: [
        {
          link: "./assets/servicesimages/icons/QAandSoftwareTesting/manualtest.png",
          color: "#4CC0AD",
          text: " Manual Testing",
        },
        {
          link: "./assets/servicesimages/icons/QAandSoftwareTesting/automation-testing.png",
          color: "#082340",
          text: "Automation Testing",
        },
        {
          link: "./assets/servicesimages/icons/QAandSoftwareTesting/web-testing.png",
          color: "#F58C76",
          text: "Website Testing",
        },
        {
          link: "./assets/servicesimages/icons/QAandSoftwareTesting/web-app-testing.png",
          color: "#7C4934",
          text: "Web Application Testing",
        },
        {
          link: "./assets/servicesimages/icons/QAandSoftwareTesting/mobileapptest.png",
          color: "#F15B40",
          text: "Mobile App Testing",
        },
        {
          link: "./assets/servicesimages/icons/QAandSoftwareTesting/mob-game-testing.png",
          color: "#F68B28",
          text: "Mobile Game Testing",
        },
        {
          link: "./assets/servicesimages/icons/QAandSoftwareTesting/database-testing.png",
          color: "#5F8AC7",
          text: "Database Testing",
        },
        {
          link: "./assets/servicesimages/icons/QAandSoftwareTesting/api-testing.png",
          color: "#00A692",
          text: "API Testing",
        },
        {
          link: "./assets/servicesimages/icons/QAandSoftwareTesting/performance-testing.png",
          color: "#9FC131",
          text: "Performance Testing",
        },
        {
          link: "./assets/servicesimages/icons/QAandSoftwareTesting/security-testing.png",
          color: "#E15858",
          text: "Security Testing",
        },
        {
          link: "./assets/servicesimages/icons/QAandSoftwareTesting/app-prod-testing.png",
          color: "#0099DD",
          text: "Application & Product Testing",
        }
      ],
    },
    
    {
      flag:false,
      id:"emerging-tech",
      head: "Emerging Technologies",
      content:
        "We apply top new technology trends to build the foundations for growth, working with you to deliver exceptional emerging technologies experiences. We design, build, manage and run disruptive technologies solutions that give you the business capabilities that fuel growth. And we don't just build the emerging technologies solutions, we help you to work in new ways to get the most out of it, so you can put your customers at the heart of the experience. We work with you to plan your emerging technologies strategy, build an execution roadmap and engineer new business capabilities that will produce clear business outcomes.",
      tablets: [
        {
          link: "./assets/servicesimages/icons/EmergingTech/Metaverse.png",
          color: "#4CC0AD",
          text: "Metaverse",
        },
        {
          link: "./assets/servicesimages/icons/EmergingTech/AR.png",
          color: "#082340",
          text: "Augmented Reality (AR)",
        },
        {
          link: "./assets/servicesimages/icons/EmergingTech/vr.png",
          color: "#F58C76",
          text: "Virtual Reality (VR)",
        },
        {
          link: "./assets/servicesimages/icons/EmergingTech/MR.png",
          color: "#7C4934",
          text: "Mixed Reality (MR)",
        },
        {
          link: "./assets/servicesimages/icons/EmergingTech/XR.png",
          color: "#F15B40",
          text: "Extended Reality (XR)",
        },
        {
          link: "./assets/servicesimages/icons/EmergingTech/Web3.png",
          color: "#F68B28",
          text: "Web3",
        },
        {
          link: "./assets/servicesimages/icons/EmergingTech/Blockchain.png",
          color: "#5F8AC7",
          text: "Blockchain",
        },
        {
          link: "./assets/servicesimages/icons/EmergingTech/DApp.png",
          color: "#00A692",
          text: "Decentralized Application",
        },
        {
          link: "./assets/servicesimages/icons/EmergingTech/AI.png",
          color: "#9FC131",
          text: "Artificial Intelligence (AI)",
        },
        {
          link: "./assets/servicesimages/icons/EmergingTech/ml.png",
          color: "#E15858",
          text: "Machine Learning (ML)",
        },
        {
          link: "./assets/servicesimages/icons/EmergingTech/Wearables.png",
          color: "#0099DD",
          text: "Wearables",
        },
        {
          link: "./assets/servicesimages/icons/EmergingTech/IoT.png",
          color: "#646464",
          text: "Internet of Things (IoT)",
        },
        {
          link: "./assets/servicesimages/icons/EmergingTech/big-data.png",
          color: "#026E81",
          text: "Big Data",
        },
        {
          link: "./assets/servicesimages/icons/EmergingTech/dataanalytics.png",
          color: "#8C1F28",
          text: "Data & Analytics",
        },
        {
          link: "./assets/servicesimages/icons/EmergingTech/superapp.png",
          color: "#00ABBD",
          text: "Super-App",
        },
        {
          link: "./assets/servicesimages/icons/EmergingTech/totalexp.png",
          color: "#32286F",
          text: "Total Experience (TX)",
        },
        {
          link: "./assets/servicesimages/icons/EmergingTech/lowcode.png",
          color: "#AA570B",
          text: "Low-Code Development",
        },
        {
          link: "./assets/servicesimages/icons/EmergingTech/nocode.png",
          color:"#8C1F28",
          text: "No-Code Development",
        },
        {
          link: "./assets/servicesimages/icons/EmergingTech/rpa.png",
          color: "#B4531C",
          text: "Robotic Process Automation (RPA)",
        },
      ],
    },
    
    {
      flag:true,
      id:"dedicated-dev-team",
      head: "Dedicated Development Team",
      content:
        "By working with our dedicated development team, you access talent with the precise expertise needed to overcome the challenges of your projects. Our dedicated development team provides a set of skills that your in-house team may lack or work jointly to attain your business goals. Our dedicated development team works alongside your company staff to create and maintain applications with the highest quality. When you hire our dedicated development team, you hire the services of an autonomously managed remote team of designers, programmers, testing engineers, analysts, and managers that will take care of your projects. We personalize our dedicated team to your unique business needs, which means that this structure can look different for your project. Our main goal remains the same: to gather the perfect team to create the digital solution that you are looking for.",
      tablets: [
        {
          link: "./assets/servicesimages/icons/DedicatedDevelopmentTeam/hire_dedicated_team.png",
          color: "#4CC0AD",
          text: "Hire Dedicated Development Team",
        },
        {
          link: "./assets/servicesimages/icons/DedicatedDevelopmentTeam/it_staff_augmentation.png",
          color: "#082340",
          text: "IT Staff Augmentation",
        },
        {
          link: "./assets/servicesimages/icons/DedicatedDevelopmentTeam/hire_dedicated_dev.png",
          color: "#F58C76",
          text: "Hire Dedicated Developers",
        }
      ],
    },
    
    {
      flag:false,
      id:"digital-marketing",
      head: "Digital Marketing",
      content:
        "To ensure comprehensive and detailed marketing of your brands, we ensure that all potential platforms of digital marketing strategies are effectively incorporated into your brands with the target market. We critically analyze the mindset of your target market and develop a digital marketing campaign through a well-coordinated use of different promotional methods which are all intended to reinforce the elements of promotions incorporated in the campaign. We incorporate a holistic approach to communication in your brand's marketing and ensure that both your online and offline marketing are aligned consistently.",
      tablets: [
        {
          link: "./assets/servicesimages/icons/DigitalMarketing/seo.png",
          color: "#4CC0AD",
          text: "Search Engine Optimization (SEO)",
        },
        {
          link: "./assets/servicesimages/icons/DigitalMarketing/sem.png",
          color: "#082340",
          text: "Search Engine Marketing (SEM)",
        },
        {
          link: "./assets/servicesimages/icons/DigitalMarketing/ppc.png",
          color: "#F58C76",
          text: "Pay Per Click (PPC)",
        },
        {
          link: "./assets/servicesimages/icons/DigitalMarketing/paidadv.png",
          color: "#7C4934",
          text: "Paid Advertising",
        },
        {
          link: "./assets/servicesimages/icons/DigitalMarketing/appstore.png",
          color: "#F15B40",
          text: "App Store Optimization (ASO)",
        },
        {
          link: "./assets/servicesimages/icons/DigitalMarketing/smm.png",
          color: "#F68B28",
          text: "Social Media Marketing",
        },
        {
          link: "./assets/servicesimages/icons/DigitalMarketing/influencer.png",
          color: "#5F8AC7",
          text: "Influencer Marketing",
        },
        {
          link: "./assets/servicesimages/icons/DigitalMarketing/email.png",
          color: "#00A692",
          text: "Email Marketing",
        },
        {
          link: "./assets/servicesimages/icons/DigitalMarketing/content-writing.png",
          color: "#9FC131",
          text: "Content Writing",
        },
        {
          link: "./assets/servicesimages/icons/DigitalMarketing/content-marketing.png",
          color: "#E15858",
          text: "Content Marketing",
        }
      ],
    },
  ];
  
const ServicesStrings = {
    allContent,heroTitle
}

export default ServicesStrings;