import NavBar from '../components/NavBar'
import FooterTheme from '../components/footer/FooterTheme'
import React from 'react';
import Project from '../components/Projects/Main'
import MetaTags from 'react-meta-tags';

function Projects() {
  return (
    <div>
        <MetaTags>
              <title>Check out a few projects done by Humans Softtech</title>
              <meta name="description" content="Check out a few works done by the Humans Softtech team on UI/UX, Website, E-commerce, Mobile App, Mobile Game, and Emerging Technologies." />
              <meta property="og:title" content="Check out a few projects done by Humans Softtech" />
              <meta property="og:image" content="./assets/homeimages/Humans.png" />
        </MetaTags>
        <NavBar/>
         <Project/>
         <FooterTheme/>
    </div>
  )
}

export default Projects
