import React from "react";
import "../../styles/Hero.css";
import "@fontsource/open-sans"; // Defaults to weight 400.

export default function HeroBanner() {
  return (
    <>
        <div className="main-hero">
            <div className="d-flex color-overlay text-white justify-content-center align-items-center">
                <h1>
                    We Are Your Trusted Partner For
                    <br />
                    Creative Digital Transformation
                </h1>
            </div>
        </div>
    </>
  )
}
      