import React from "react";
import "../../styles/footer/Footer.css";
import FormFooter from "./FormFooterWhite";
import LinkFooter from "./LinkFooterWhite";

export default function FooterWhite() {

  return (
    <>
      <FormFooter/>
      <LinkFooter/>
    </>
  );
}
