import NavBar from '../components/NavBar';
import AboutHero from '../components/about/AboutHero';
import AboutBlock from '../components/about/AboutBlock';
import QuoteOne from '../components/about/QuoteOne';
import DrivesUs from '../components/about/DrivesUs';
import QuoteTwo from '../components/about/QuoteTwo';
import Expertise from '../components/about/Expertise';
import Team from "../components/about/Team";
import FooterWhite from '../components/footer/FooterWhite';
import MetaTags from 'react-meta-tags';


import React from 'react'
//About us Page
function About() {
  return (
    <div>
        <MetaTags>
              <title>About Humans Softtech</title>
              <meta name="description" content="Discover stories about our culture, philosophy, team, and how Humans Softtech creative digital transformation services are impacting others." />
              <meta property="og:title" content="About Humans Softtech" />
              <meta property="og:image" content="./assets/homeimages/Humans.png" />
        </MetaTags>
        <NavBar/>
        <AboutHero/>
        <AboutBlock/>
        <QuoteOne/>
        <DrivesUs/>
        <QuoteTwo/>
        <Expertise/>
        <Team/>
        <FooterWhite/>


    </div>
  )
}

export default About