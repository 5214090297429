import {BrowserRouter, Routes,Route, useLocation} from 'react-router-dom';
import React, { useEffect } from 'react'
import "../../styles/services.css";
import ServicesStrings from "../../utils/strings/ServicesStrings";
import ScrollSection from "./ScrollSection";

function AllContent({ items }) {
  const { pathname, hash, key } = useLocation();


  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  return (
    <div>
    {ServicesStrings.allContent.map((item, index) => (
    
    <div
      key={index}
      className=" all-content-container py-md-5" id={item.id}
      style={{
        backgroundColor: item.flag ? "#FFDAA5D6" : "white",
        position: "relative",
      }}
    >
      <div className="container-fluid px-4 px-md-5">
      <div className="content-head fs-1 fw-bold mb-md-5">{item.head}</div>
      <div className="content">{item.content}</div>

      </div>
      <ScrollSection flag={item.flag} items={item.tablets} />

      <div className="text-end">
        <img
          src="./assets/servicesimages/slider-arrow3.png"
          alt=""
          srcset=""
          className="slider-arrow"
        />
      </div>
    </div>
    ))}
    </div>
  );
}

export default AllContent;
