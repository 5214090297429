const lists = [{name:"UI/UX Design",
                list:['UI/UX Research','UI/UX Strategy','UI/UX Mapping','Wireframing','Prototyping',
                'Website Design','Web Application Design','Mobile App Design','Mobile Game Design',
                'Software Application Design','Product Design','Graphics Design'],
                redirect:'/services#ui-ux',
                img:"uiux"
                },
                
                {name:"Website Development",
                list:['CMS Website Development','WordPress Website Development','Elementor Website Development',
                'Divi Website Development','WPBakery Website Development','Drupal Development',
                'Joomla Development','DotNetNuke Development'],
                redirect:'/services#web-dev',
                img:"webdev"
            },

                {name:"Ecommerce Development",
                list:['WooCommerce Development','Shopify Development','Magento Development',
                'BigCommerce Development','Online Marketplace','Ecommerce Website Design & Development'],
                redirect:'/services#ecom-dev',
                img:"ecomdev"
            },
                
                {name:"Mobile App Development",
                list:['iOS Development','Android Development','React Native Development','Flutter Development',
                'Xamarin Development','Ionic Development'],
                redirect:'/services#mob-app-dev',
                img: "mobileappdev"
            },

                {name:"Mobile Game Development",
                list:['Unity Game Development','Unreal Game Development','iOS Game Development',
                'Android Game Development','2D & 3D Game Development','AR & VR Game Development'],
                redirect:'/services#mob-game-dev',
                img: "mobilegamedev"
            },

                {name:"Application & Product Development",
                list:['Enterprise Resource Planning (ERP)','Customer Relationship Management (CRM)',
                'Content Management System (CMS)','Project Management Software (PMS)','Document Management System (DMS)',
                'Business Processs Management (BPM)'],
                redirect:'/services#app-prod-dev',
                img: "appdev"
            },
                
                {name:"Frontend Development",
                list:['HTML','CSS','Bootstrap','JavaScript','jQuery','React',
                'Angular','Vue.js','TypeScript','Backbone.js'],
                redirect:'/services#front-dev',
                img: "frontenddev"
            },
                
                {name:"Backend Development",
                list:['Microsoft .NET','Java','PHP (Laravel)','Python (Django, Flask)','Golang',
                'Ruby on Rails','JavaScript (Node.js, Express.js, Meteor.js)'],
                redirect:'/services#back-dev',
                img: "backenddev"
            },
                
                {name:"Full Stack Development",
                list:['MEAN Stack (MongoDB, Express.js, Angular, Node.js)',
                'MERN Stack (MongoDB, Express.js, React, Node.js)','MEVN Stack (MongoDB, Express.js, Vue.js, Node.js)',
                'LAMP Stack (Linux, Apache, MySQL, PHP)'],
                redirect:'/services#full-dev',
                img: "fullstackdev"
            },
                
                {name:"Cloud Computing",
                list:['Cloud Strategy','Cloud Consulting','Cloud Migration',
                'Cloud Management','Cloud Optimization','Cloud Security','Cloud Native Computing','Cloud Application Development'],
                redirect:'/services#cloud-comp',
                img: "cloudcom"
            },
                
                {name:"DevOps",
                list:['Continuous Integration (CI)','Continuous Delivery (CD)',
                'Microservices','Infrastructure as Code (IaC)','Monitoring and Logging','Communication and Collaboration'],
                redirect:'/services#devops',
                img: "devops"
            },
                
                {name:"QA & Software Testing",
                list:['Manual Testing','Automation Testing','Website Testing',
                'Web Application Testing','Mobile App Testing','Mobile Game Testing','Database Testing','API Testing',
                'Performance Testing','Security Testing'],
                redirect:'/services#qa-test',
                img: "softtest"
            },

                {name:"Emerging Technologies",
                list:['Metaverse','Augmented Reality (AR)','Virtual Reality (VR)',
                'Mixed Reality (MR)','Extended Reality (XR)','Web3','Blockchain','Decentralized Application','Artificial Intelligence (AI)',
                'Machine Learning (ML)','Wearables','Internet of Things (IoT)'],
                redirect:'/services#emerging-tech',
                img: "emerging"
            },

                {name:"Dedicated Development Team",
                list:['Hire Dedicated Development Team','IT Staff Augmentation',
                'Hire Dedicated Developers'],
                redirect:'/services#dedicated-dev-team',
                img: "hiredev"
            },

                {name:"Digital Marketing",
                list:['Search Engine Optimization (SEO)','Search Engine Marketing (SEM)',
                'Pay Per Click (PPC)','Paid Advertising','App Store Optimization (ASO)','Social Media Marketing','Influencer Marketing',
                'Email Marketing'],
                redirect:'/services#digital-marketing',
                img: "digimark"
            }
        ]

const homeProj = [  {name:"Cowboy",
                    boxcolor:'#D0F8C9',
                    image:'./assets/homeimages/ourwork/cowboy.png',
                    button:'#0D5900',
                    link:'/projects#project-cowboy'
                    },

                    {name:"Checkout",
                    boxcolor:'#ADC0E4',
                    image:'./assets/homeimages/ourwork/checkout.png',
                    button:'#0E2248',
                    link:'/projects#project-checkout'
                    },

                    {name:"Superhuman",
                    boxcolor:'#FEE4D3',
                    image:'./assets/homeimages/ourwork/superhuman.png',
                    button:'#E73434',
                    link:'/projects#project-superhuman'
                    },

                    {name:"Copper",
                    boxcolor:'#EBCEE4',
                    image:'./assets/homeimages/ourwork/copper.png',
                    button:'#FF2350',
                    link:'/projects#project-copper'
                    },

                    {name:"BoConcept",
                    boxcolor:'#E8D3B6',
                    image:'./assets/homeimages/ourwork/boconcept.png',
                    button:'#B73B26',
                    link:'/projects#project-boconcept'
                    },

                    {name:"Tiramisu",
                    boxcolor:'#D2F0EE',
                    image:'./assets/homeimages/ourwork/tiramisu.png',
                    button:'#1E3758',
                    link:'/projects#project-tiramisu'
                    },
                    
                    {name:"ClearMotion",
                    boxcolor:'#B0B6B6',
                    image:'./assets/homeimages/ourwork/clearmotion.png',
                    button:'#3F4545',
                    link:'/projects#project-clearmotion'
                    },

                    {name:"Credit Karma", 
                    boxcolor:'#FFD1E9',
                    image:'./assets/homeimages/ourwork/creditkarma.png',
                    button:'#BC3665',
                    link:'/projects#project-creditkarma'
                    },

                    {name:"Boosted",
                    boxcolor:'#BFC6FF',
                    image:'./assets/homeimages/ourwork/boosted.png',
                    button:'#007690',
                    link:'/projects#project-boosted'
                    },
            ]

const jobopen = [{position:"UI/UX Designer",
                    link:"/services#ui-ux",
                    expertise:"Figma, Sketch, Adobe XD, Illustrator, Photoshop, InVision Studio",
                    experience:"3+ Years",
                    openings: "11",
                    location: "Ahmedabad",
                    applylink:"#"},

                    {position:"Website Developer",
                    link:"#",
                    expertise: "WordPress, Drupal, Joomla, DotNetNuke, Kentico, Umbraco, Sitecore, dotCMS, HubSpot, Webflow, Wix, Squarespace, Liferay",
                    experience: "3+ Years",
                    openings: "13",
                    location: "Ahmedabad",
                    applylink:"#"},

                    {position:"Ecommerce Developer",
                    link:"#",
                    expertise: "WooCommerce, Shopify, Magento, BigCommerce",
                    experience: "3+ Years",
                    openings: "8",
                    location: "Ahmedabad",
                    applylink:"#"},
                    
                    {position:"Mobile App Developer",
                    link:"#",
                    expertise: "iOS (Objective-C, Swift), Android (Java, Kotlin), React Native, Flutter, Xamarin, Ionic",
                    experience: "3+ Years",
                    openings: "12",
                    location: "Ahmedabad",
                    applylink:"#"},

                    {position:"Mobile Game Developer",
                    link:"#",
                    Expertise: "Unity, Unreal Engine, iOS (Objective-C, Swift, SpriteKit), Android (Java, Kotlin, Android Game Development Kit)",
                    experience: "3+ Years",
                    openings: "8",
                    location: "Ahmedabad",
                    applylink:"#"},
                    
                    {position:"Frontend Developer",
                    link:"#",
                    expertise: "HTML, CSS, Bootstrap, JavaScript, jQuery, React, Angular, Vue.js, TypeScript, Backbone.js",
                    experience: "3+ Years",
                    openings: "10",
                    location: "Ahmedabad",
                    applylink:"#"},
                    
                    {position:"Backend Developer",
                    link:"#",
                    expertise: "HTML, CSS, Bootstrap, JavaScript, jQuery, React, Angular, Vue.js, TypeScript, Backbone.js",
                    experience: "3+ Years",
                    openings: "10",
                    location: "Ahmedabad",
                    applylink:"#"},

                    {position:"Full Stack Developer",
                    link:"#",
                    expertise: "MEAN Stack (MongoDB, Express.js, Angular, Node.js), MERN Stack (MongoDB, Express.js, React, Node.js), MEVN Stack (MongoDB, Express.js, Vue.js, Node.js), LAMP Stack (Linux, Apache, MySQL, PHP)",
                    experience: "3+ Years",
                    openings: "11",
                    location: "Ahmedabad",
                    applylink:"#"},

                    {position:"Cloud Engineer",
                    link:"#",
                    expertise: "Google Cloud Platform (GCP), Amazon Web Services (AWS), Microsoft Azure",
                    experience: "3+ Years",
                    openings: "4",
                    location: "Ahmedabad",
                    applylink:"#"},

                    {position:"DevOps Engineer",
                    link:"#",
                    expertise: "Bitbucket, GitHub, GitLab, Apache Ant, Docker, Gradle, Apache Maven, Apache JMeter, Burp Suite, Jenkins, and more",
                    experience: "3+ Years",
                    openings: "4",
                    location: "Ahmedabad",
                    applylink:"#"},

                    {position:"Content Writer",
                    link:"#",
                    applylink:"#"},

                    {position:"Data Scientist",
                    link:"#",
                    expertise: "Talend, Qlik, Tableau, Looker, ThoughtSpot, Zoho Analytics, IBM Analytics, Microsoft Power BI",
                    experience: "3+ Years",
                    openings: "4",
                    location: "Ahmedabad",
                    applylink:"#"},
                    
                    {position:"HR Recruiter",
                    link:"#",
                    expertise: "Talent acquisition and recruitment, Recruiting operations, Social Media, Networking, Scheduling interviews, Understanding technology, and more",
                    experience: "3+ Years",
                    openings: "5",
                    location: "Ahmedabad",
                    applylink:"#"},
                    
                    {position:"Project Manager",
                    link:"#",
                    expertise: "Project delivery management, project management tools, team management, time management, project estimation, project planning, project scheduling, cost management, clients communication, and more",
                    experience: "3+ Years",
                    openings: "5",
                    location: "Ahmedabad",
                    applylink:"#"},

                    {position:"Database Administrator",
                    expertise: "MySQL, PostgreSQL, SQLite, MongoDB, Firebase, Amazon RDS, Microsoft SQL Server, Amazon DynamoDB, Microsoft Azure SQL Database",
                    experience: "3+ Years",
                    openings: "6",
                    location: "Ahmedabad",
                    link:"#",
                    applylink:"#"},

                    {position:"Emerging Technologies Expert",
                    link:"#",
                    expertise: "Metaverse, AR, VR, MR, XR, Web3, Blockchain, AI, ML, Wearables, IoT, RPA, and more",
                    experience: "3+ Years",
                    openings: "7",
                    location: "Ahmedabad",
                    applylink:"#"},

                    {position:"Business Development Executive",
                    link:"#",
                    expertise: "Generate and close the IT services leads through market research, cold calling, bidding, social networking, and other techniques. IT services sales, project estimation, project documentation, clients communication, and more",
                    experience: "3+ Years",
                    openings: "14",
                    location: "Ahmedabad",
                    applylink:"#"},

                    {position:"Digital Marketing Executive",
                    link:"#",
                    expertise: "SEO, SEM, PPC, Paid Advertising, ASO, Social Media Marketing, Influencer Marketing, Email Marketing, Content Marketing",
                    experience: "3+ Years",
                    openings: "6",
                    location: "Ahmedabad",
                    applylink:"#"},

                    {position:"Software Testing Engineer",
                    link:"#",
                    expertise: "Selenium, Katalon, Appium, SoapUI, Postman, BlazeMeter, Trello, SpecFlow, BrowserStack, Perfecto, AWS Device Farm",
                    experience: "3+ Years",
                    openings: "8",
                    location: "Ahmedabad",
                    applylink:"#"},
                    
]

const abouthumans = [
                {
                    heading: "Humans Softtech is a leading technology services and consulting company focused on designing and developing innovative solutions that address clients' most complex creative digital transformation needs."
                },

                {
                    heading: "We leverage our holistic portfolio of capabilities in consulting, design, engineering, operations, and emerging technologies to help clients realize their boldest dreams and build future-ready sustainable businesses." 
                }
]

const whywedo = ["Humans Softtech is a leading custom website, mobile app, ecommerce, mobile game, software, product design & development, cloud computing, staff augmentation, dedicated development team, technologies consulting services and business solutions providing company. We transform businesses by generating ideas, building products, and accelerating growth."]

const techweworkwith = [{name:"UI/UX",
                pics:[{ image:['./assets/homeimages/techwework/uiux/figma.png'],caption: "Figma"},
                    { image:['./assets/homeimages/techwework/uiux/sketch.png'], caption: "Sketch"},
                    { image:['./assets/homeimages/techwework/uiux/xd.png'], caption: "Adobe XD"},
                    { image:['./assets/homeimages/techwework/uiux/illustrator.png'], caption: "Illustrator"},
                    { image:['./assets/homeimages/techwework/uiux/photoshop.png'], caption: "Photoshop"},
                    { image:['./assets/homeimages/techwework/uiux/invision.png'], caption: "Invision"},        
                    ]
            },
            {name:"Website",
                pics:[{ image:['./assets/homeimages/techwework/website/wordpress.png'], caption: "WordPress"},
                    { image:['./assets/homeimages/techwework/website/elementor.png'], caption: "Elementor"},
                    { image:['./assets/homeimages/techwework/website/wpbakery.png'], caption: "WPBakery"},
                    { image:['./assets/homeimages/techwework/website/drupal.png'],caption: "Drupal"},
                    { image:['./assets/homeimages/techwework/website/joomla.png'],caption: "Joomla"},
                    { image:['./assets/homeimages/techwework/website/dotnetnuke.png'],caption: "DotNetNuke"},
                    ]
            },
            {name:"Ecommerce",
                pics:[{ image:['./assets/homeimages/techwework/ecommerce/woocom.png'], caption: "WooCommerce"},
                    { image:['./assets/homeimages/techwework/ecommerce/shopify.png'], caption: "Shopify"},
                    { image:['./assets/homeimages/techwework/ecommerce/magento.png'], caption: "Magento"},
                    { image:['./assets/homeimages/techwework/ecommerce/bigcom.png'],caption: "BigCommerce"}
                    ]
            },
            {name:"Mobile App",
                pics:[{ image:['./assets/homeimages/techwework/mobileapp/ios.png'], caption: "iOS"},
                    { image:['./assets/homeimages/techwework/mobileapp/android.png'],caption: "Android"},
                    { image:['./assets/homeimages/techwework/mobileapp/react.png'], caption: "React Native"},
                    { image:['./assets/homeimages/techwework/mobileapp/flutter.png'], caption: "Flutter"},
                    { image:['./assets/homeimages/techwework/mobileapp/xamarin.png'], caption: "Xamarin"},
                    { image:['./assets/homeimages/techwework/mobileapp/ionic.png'], caption: "Ionic"}
                    ]
            },
            {name:"Mobile Game",
                pics:[{ image:['./assets/homeimages/techwework/mobilegame/unity.png'], caption: "Unity"},
                    { image:['./assets/homeimages/techwework/mobilegame/unreal-engine.png'], caption: "Unreal Engine"},    
                    { image:['./assets/homeimages/techwework/mobilegame/react.png'], caption: "React Native"},
                    { image:['./assets/homeimages/techwework/mobilegame/flutter.png'], caption: "Flutter"},
                    { image:['./assets/homeimages/techwework/mobilegame/ios.png'], caption: "iOS"},
                    { image:['./assets/homeimages/techwework/mobilegame/android.png'],caption: "Android"},
                    ]
            },
            {name:"Frontend",
            pics:[
                { image:['./assets/homeimages/techwework/frontend/html5.png'], caption: "HTML"},
                { image:['./assets/homeimages/techwework/frontend/css3.png'], caption: "CSS"},
                { image:['./assets/homeimages/techwework/frontend/bootstrap.png'], caption: "Bootstrap"},
                { image:['./assets/homeimages/techwework/frontend/javascript.png'],caption: "JavaScript"},
                { image:['./assets/homeimages/techwework/frontend/jquery.png'], caption: "jQuery"},
                { image:['./assets/homeimages/techwework/frontend/react.png'], caption: "React"},    
                ]
            },
            {name:"Backend",
            pics:[{ image:['./assets/homeimages/techwework/backend/dotnet.png'],caption: ".NET"},
                { image:['./assets/homeimages/techwework/backend/java.png'], caption: "Java"},
                { image:['./assets/homeimages/techwework/backend/php.png'], caption: "PHP"},
                { image:['./assets/homeimages/techwework/backend/python.png'], caption: "Python"}, 
                { image:['./assets/homeimages/techwework/backend/golang.png'], caption: "Golang"},
                { image:['./assets/homeimages/techwework/backend/rubyonrails.png'], caption: "Ruby on Rails"},   
                ]
            },
            {name:"Full Stack",
            pics:[
                { image:['./assets/homeimages/techwework/fullstack/mean.png'], caption: "MEAN"},
                { image:['./assets/homeimages/techwework/fullstack/mern.png'], caption: "MERN"},
                { image:['./assets/homeimages/techwework/fullstack/mevn.png'], caption: "MEVN"},
                { image:['./assets/homeimages/techwework/fullstack/lamp.png'], caption: "LAMP"}, 
                { image:['./assets/homeimages/techwework/fullstack/jamstack.png'],caption: "Jamstack"}
                ]
            },
            {name:"Database",
            pics:[{ image:['./assets/homeimages/techwework/database/mysql.png'], caption: "MySQL"},
                { image:['./assets/homeimages/techwework/database/postgresql.png'], caption: "PostgreSQL"},
                { image:['./assets/homeimages/techwework/database/sqlite.png'],caption: "SQLite"},
                { image:['./assets/homeimages/techwework/database/mongodb.png'], caption: "MongoDB"},
                { image:['./assets/homeimages/techwework/database/firebase.png'], caption: "Firebase"}, 
                { image:['./assets/homeimages/techwework/database/amazonrds.png'], caption: "Amazon RDS"},
                ]
            },
            {name:"Cloud",
            pics:[{ image:['./assets/homeimages/techwework/cloud/googlecloud.png'], caption: "Google Cloud"},
                { image:['./assets/homeimages/techwework/cloud/aws.png'],caption: "AWS"},
                { image:['./assets/homeimages/techwework/cloud/dynamics.png'], caption: "Microsoft Azure"},
                ]
            },
            {name:"DevOps",
            pics:[{ image:['./assets/homeimages/techwework/devops/bitbucket.png'], caption: "Bitbucket"},
                { image:['./assets/homeimages/techwework/devops/github.png'], caption: "GitHub"},
                { image:['./assets/homeimages/techwework/devops/gitlab.png'], caption: "GitLab"},
                { image:['./assets/homeimages/techwework/devops/apache.png'],caption: "Apache Ant"},
                { image:['./assets/homeimages/techwework/devops/docker.png'], caption: "Docker"},
                { image:['./assets/homeimages/techwework/devops/gradle.png'], caption: "Gradle"},    
                ]
            },
            {name:"Software Testing",
            pics:[{ image:['./assets/homeimages/techwework/softwaretesting/selenium.png'], caption: "Selenium"},
                { image:['./assets/homeimages/techwework/softwaretesting/katalon.png'], caption: "Katalon"},
                { image:['./assets/homeimages/techwework/softwaretesting/appium.png'],caption: "Appium"},
                { image:['./assets/homeimages/techwework/softwaretesting/soapui.png'], caption: "SoapUI"},
                { image:['./assets/homeimages/techwework/softwaretesting/postman.png'], caption: "Postman"},
                { image:['./assets/homeimages/techwework/softwaretesting/blazemeter.png'], caption: "BlazeMeter"},    
                ]
            },
            {name:"Data and Analytics",
            pics:[{ image:['./assets/homeimages/techwework/data/talend.png'], caption: "Talend"},
                { image:['./assets/homeimages/techwework/data/qlik.png'], caption: "Qlik"},
                { image:['./assets/homeimages/techwework/data/tableau.png'], caption: "Tableau"},
                { image:['./assets/homeimages/techwework/data/looker.png'], caption: "Looker"},
                { image:['./assets/homeimages/techwework/data/blob.png'],caption: "ThoughtSpot"},
                { image:['./assets/homeimages/techwework/data/zoho.png'],caption: "Zoho Analytics"},
                ]
            }
]


const Strings = {
    whywedo,
    abouthumans,
    lists,
    homeProj,
    jobopen,
    techweworkwith
}



export default Strings;