

var allHead = 
    {
        aboutBlock:"About Humans Softtech",
        drivesUs:"What Drives Us",
        expertise:"Our Areas of Expertise",
        team:"Meet the Team",
    }
var drivesUs = [
    {
        textHead:"Spirit Of Humans Softtech",
        textContent:"These values are our bedrock. They define and make us. Our character and destinies are energized by our values.",
        backgroundColor:"#F9FEFF",
        boxShadow1:"1.8rem 1.8rem  0px -10px #0F4B52,-1.8rem -1.8rem  0px -10px #0F4B52, 0 0 2rem #ccc",
        boxShadow1Color:"#0F4B52",
        // boxShadow2:"-15px -15px rgba(15, 75, 82, 1)",
        textPoints:[
            {
                point:"Be passionate about client's success",
            },
            {
                point:"Treat each person with respect",
            },
            {
                point:"Be global and responsible",
            },
            {
                point:"Unyielding integrity in everything we do",
            }
        ],
    },
    {
        
        textHead:"Habits Of Humans Softtech",
        textContent:"When our behaviors and ways of working consistently reflect our values. We see these habits in action.",
        backgroundColor:"#FEF9F5",
        boxShadow1:"1.8rem 1.8rem  0px -10px #BC3665, -1.8rem -1.8rem  0px -10px #BC3665,0 0 2rem #ccc",
        boxShadow1Color:"#BC3665",
        // boxShadow2:"-15px -15px rgba(188, 54, 101,1)",
        textPoints:[
            {
                point:"Being respectful",
            },
            {
                point:"Being responsive",
            },
            {
                point:"Always communicating",
            },
            {
                point:"Building trust",
            }
        ],
    }
]
var quoteSection = 
    {
        quoteOne:"The new normal is yet to be defined; creativity and technology have the power to ensure that what comes next is better than what came before.",
        nameOne:"Sanjay Patel",
        positionOne:"Founder & CEO",
        quoteTwo:"Creative digital transformation is fuelled by ideas that are born from a powerful sense of purpose and executed in a massively disruptive way.",
        nameTwo:"Kamlesh Khamar",
        positionTwo:"Co-Founder & CEO",
        quoteThree:"The biggest part of our creative digital transformation is changing the way we think.",
        nameThree:"Bhavesh Patel",
        positionThree:"Co-Founder",
    }

    var aboutSection = [
        {
        boxContent:"Humans Softtech is a leading technology services and consulting company focused on designing and developing innovative solutions that address client's most complex creative digital transformation needs.",
        color:"#EE6C4D",
        boxShadow:"0px 0px 7px #EE6C4D",
        boxShadowsmall:"0px 0px 3px #EE6C4D",
        },
        {
        boxContent:"We leverage our holistic portfolio of capabilities in consulting, design, engineering, operations, and emerging technologies to help clients realize their boldest dreams and build future-ready sustainable businesses.",
        color:"#5F8AC7",
        boxShadow:"0px 0px 7px #5F8AC7",
        boxShadowsmall:"0px 0px 3px #5F8AC7",
        },
        {
        boxContent:"A company recognized globally for its comprehensive portfolio of services, a strong commitment to sustainability, and good corporate citizenship, we have over 50 dedicated team members serving clients across multiple countries.",
        color:"#ED9538",
        boxShadow:"0px 0px 7px #ED9538",
        boxShadowsmall:"0px 0px 3px #ED9538",
        },
        {
        boxContent:"We deliver on the promise of helping our clients, colleagues, and communities thrive in an ever-changing world.",
        color:"#05AB90",
        boxShadow:"0px 0px 7px #05AB90",
        boxShadowsmall:"0px 0px 3px #05AB90",
        }
    ]

    var expertiseSection = [
        {
            color:"#00A692",
            image:"./assets/aboutimages/icons/ui-ux.png",
            link:"/services#ui-ux",
            text:"UI/UX Design",
        },
        {
            color:"#082340",
            image:"./assets/aboutimages/icons/website-development.png",
            link:"/services#web-dev",
            text:"Website Development",
        },
        {
            color:"#F58C76",
            image:"./assets/aboutimages/icons/ecommerce-development.png",
            link:"/services#ecom-dev",
            text:"Ecommerce Development",
        },
        {
            color:"#7C4934",
            image:"./assets/aboutimages/icons/mobile-app-development.png",
            link:"/services#mob-app-dev",
            text:"Mobile App Development",
        },
        {
            color:"#F15B40",
            image:"./assets/aboutimages/icons/mobile-game-development.png",
            link:"/services#mob-game-dev",
            text:"Mobile Game Development",
        },
        {
            color:"#F68B28",
            image:"./assets/aboutimages/icons/appandprod.png",
            link:"/services#app-prod-dev",
            text:"Application and Product Development",
        },
        {
            color:"#5F8AC7",
            image:"./assets/aboutimages/icons/frontend-development.png",
            link:"/services#front-dev",
            text:"Frontend Development",
        },
        {
            color:"#00A692",
            image:"./assets/aboutimages/icons/backend-development.png",
            link:"/services#back-dev",
            text:"Backend Development",
        },
        {
            color:"#9FC131",
            image:"./assets/aboutimages/icons/fullstack-development.png",
            link:"/services#full-dev",
            text:"Full Stack Development",
        },
        {
            color:"#E15858",
            image:"./assets/aboutimages/icons/cloud-computing.png",
            link:"/services#cloud-comp",
            text:"Cloud Computing",
        },
        {
            color:"#0099DD",
            image:"./assets/aboutimages/icons/devops.png",
            link:"/services#devops",
            text:"DevOps",
        },
        {
            color:"#646464",
            image:"./assets/aboutimages/icons/software-testing.png",
            link:"/services#qa-test",
            text:"QA and Software Testing",
        },
        {
            color:"#026E81",
            image:"./assets/aboutimages/icons/emerging-technologies.png",
            link:"/services#emerging-tech",
            text:"Emerging Technologies",
        }, 
        {
            color:"#8C1F28",
            image:"./assets/aboutimages/icons/hire-dedicated-team.png",
            link:"/services#dedicated-dev-team",
            text:"Hire Dedicated Development Team",
        },
        {
            color:"#00ABBDDE",
            image:"./assets/aboutimages/icons/digital-marketing.png",
            link:"/services#digital-marketing",
            text:"Digital Marketing",
        },
    ]
const AboutStrings = {
    allHead, aboutSection, expertiseSection, quoteSection, drivesUs
}

export default AboutStrings;