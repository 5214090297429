import NavBar from '../components/NavBar';
import ContactHero from '../components/contact/ContactHero';
import FooterTheme from '../components/footer/FooterTheme';

import MetaTags from 'react-meta-tags';
function Contact() {
  return (
    <div>
      {/* Contact Page */}

        <MetaTags>
              <title>Contact Humans Softtech for help, office location, and to start your creative digital transformation project</title>
              <meta name="description" content="Humans Softtech is here to help. Discover our office location, different ways to contact us, and start your creative digital transformation project today." />
              <meta property="og:title" content="Contact Humans Softtech for help, office location, and to start your creative digital transformation project" />
              <meta property="og:image" content="./assets/homeimages/Humans.png" />
        </MetaTags>
        <NavBar/>
        <ContactHero/>
        <FooterTheme/>
    </div>
  )
}

export default Contact