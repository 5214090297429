import Header from '../components/NavBar'
import FooterTheme from '../components/footer/FooterTheme'
import TermsHero from '../components/termsofuse/TermsHero'

import MetaTags from 'react-meta-tags';

function Terms() {
  return (
    <div>
        
        <MetaTags>
              <title>Terms of Use - Humans Softtech</title>
              <meta property="og:title" content="Terms of Use - Humans Softtech" />
              <meta property="og:image" content="./assets/homeimages/Humans.png" />
        </MetaTags>
        <Header/>
        <TermsHero/>
        <FooterTheme/>
    </div>
  )
}

export default Terms