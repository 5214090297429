const project = [
    { 
      bgColor: "#d0f8c9",
      id:"project-cowboy",
      heading: "Cowboy",
      imgURL: "./assets/projectimage/projectCowboy.png",
      line1:
        "Working together almost from inception, we helped the European e-bike startup develop a winning mobile app with a great experience that speaks to a youthful market.",
      boldText: "Best mobile app development company!",
      starCount: 5,
      review:
        "Mobile app and technology are our main focus at Cowboy. When searching for the right mobile app development partner, Humans Softtech directly stood out from the pack: the level of quality and consistency in their work is unmatched. From day one, we collaborated with their team as one, truly working together as if we were one company.",
      author: "Adrien Roose | Founder & CEO at Cowboy",
    },
    {
      bgColor: "#AED4D5",
      id:"project-checkout",
      heading: "Checkout",
      imgURL: "./assets/projectimage/projectCheckout.png",
      line1:
        "Before moving in on the U.S. market, this bootstrapped fintech startup came to Humans Softtech for website design and development that would take it to a billion United States dollar valuation in a record deal.",
      boldText: "Best website design and development company!",
      starCount: 5,
      review:
        "We at Checkout were very impressed with all aspects of our website. We would highly recommend Humans Softtech to anyone looking to design UI/UX and build a website.",
      author: "Ott Kaukver | CTO at Checkout",
    },
    {
      bgColor: "#FBDCAF",
      id:"project-superhuman",
      heading: "Superhuman",
      imgURL: "./assets/projectimage/projectSuper.png",
      line1:
        "We helped Superhuman develop a groundbreaking and lightning-fast email client application for busy people. Their millions of United States dollar valuation proves the value of user experiences in enterprise software.",
      boldText: "Excellent application and product development team!",
      starCount: 5,
      review:
        "Together, we build an email client application that puts experiences before brand. It's an email client that highlights what you can experience at Superhuman, and everything the application has to offer.",
      author: "Conrad Irwin | Founder & CTO at Superhuman",
    },
    {
      bgColor: "#FFCFF3",
      id:"project-copper",
      heading: "Copper",
      imgURL: "./assets/projectimage/projectCopper.png",
      line1:
        "Complete re-branding, re-imaging, and re-everything for a Copper CRM gearing up to take on Salesforce. To better position itself as a leader in the rapidly changing CRM market, the company came to Humans Softtech for a complete brand rethink, including a UI/UX and website.",
      boldText: "Awesome rebranding, redesign UI/UX and website!",
      starCount: 5,
      review:
        "We were thrilled to work with Humans Softtech on our rebrand. We knew it would be a challenge to redesign UI/UX, website, and new visual identity on such a short timeline but the process the Humans Softtech team led was very efficient and extremely collaborative. It felt like one team working toward the same goal.",
      author: "Derek Skaletsky | Head of Product at Copper",
    },
    {
      bgColor: "#E8D3B6",
      id:"project-boconcept",
      heading: "BoConcept",
      imgURL: "./assets/projectimage/projectBo.png",
      line1:
        "BoConcept needed centralized control over its eCommerce website, but it also needed to empower franchisees in multiple countries by giving them the e-commerce functionalities to engage with local customers. As an eCommerce website development service provider, we implemented an eCommerce solution that allows them to 360-degree customer view by centralizing their CRM and data with an eCommerce website.",
      boldText: "Best eCommerce website development company!",
      starCount: 5,
      review:
        "The work you have contributed here to our overall response to COVID has been second-to-none and we are grateful for all the effort you put in. The Humans Softtech e-commerce team was a big part of that effort.",
      author: "Paula Mc Guinness | CMO at BoConcept",
    },
    {
      bgColor: "#D2F0EE",
      id:"project-tiramisu",
      heading: "Tiramisu",
      imgURL: "./assets/projectimage/projectTirmisu.png",
      line1:
        "Tiramisu wanted to develop a car drifting & racing mobile game with the best drifting experience, next-gen graphics, drive-around breathtaking locations, and multiplayer mode functionality.",
      boldText: "Favorite mobile game development company!",
      starCount: 5,
      review:
        "At Tiramisu, our dream was to develop the best car drifting & racing mobile game. After working with the Humans Softtech mobile game development team, we feel that they worked very hard to deliver what we wanted.",
      author: "Evren Gulcıg | Co-Founder at Tiramisu",
    },
    {
      bgColor: "#B0B6B6",
      id:"project-clearmotion",
      heading: "ClearMotion",
      imgURL: "./assets/projectimage/projectClear.png",
      line1:
        "This innovative startup wants to change the way our cars ride. Our unique, tech-first approach to the brand visual identity and website experience no doubt helped it reach a billion United States dollar valuation.",
      boldText: "Best visual identity and website design!",
      starCount: 5,
      review:
        "Working with Humans Softtech has been valuable to the progression of our brand visual identity and website. The design guidance they provide has exceeded our expectations and gives us an impressive standard that we are proud to uphold.",
      author: "Shakeel Avadhany | Founder at ClearMotion",
    },
    {
      bgColor: "#FFD1E9",
      id:"project-creditkarma",
      heading: "Credit Karma ",
      imgURL: "./assets/projectimage/projectCredit.png",
      line1:
        "This startup wants to help its customers enjoy better financial health. We worked with them for a long time on the design and development of their mobile app.",
      boldText: "Top mobile app design and development agency!",
      starCount: 5,
      review:
        "Humans Softtech isn’t your average agency. Their ability to think creatively, challenge assumptions, challenge the industry at large, and dig deep to understand who we are and what we are trying to do, all led to a successful mobile app product with a rewarding outcome that we feel very proud of.",
      author: "Ryan Graciano | CTO at Credit Karma",
    },
    {
      bgColor: "#EFEFEF",
      id:"project-boosted",
      heading: "Boosted",
      imgURL: "./assets/projectimage/projectBoosted.png",
      line1:
        "We worked with the electric scooters, skateboards, and longboards makers on telling and selling their story through an updated brand and eCommerce website experience.",
      boldText: "Top eCommerce website development company!",
      starCount: 5,
      review:
        "Humans Softtech addressed every concern we had. They showed us how to maintain and update our new eCommerce website. Working with them has been a great experience. You are in good hands if you hire them.",
      author: "Sanjay Dastoor | CEO at Boosted",
    },
  ];

const ProjectStrings = {
    project
}

export default ProjectStrings;