import "../../styles/About.css";
import AboutStrings from "../../utils/strings/AboutStrings";
import hyphen from "./hyphenn.png"

function DrivesUs() {
  return (
    <div>
        <div className="container-fluid px-4  px-md-5">
            <div className="about-heading mt-3  py-md-4">{AboutStrings.allHead.drivesUs}</div>
        </div>
        
        <div className="row  container-fluid px-4  px-md-5 mb-3">
        {AboutStrings.drivesUs.map((item, index) => (
       
            <div className="col-11 col-xl-5 box-1 my-5 mx-auto  justify-content-center " style={{backgroundColor:item.backgroundColor, borderRadius:"35px",boxShadow:`1.8rem 1.8rem  0px -12.5px ${item.boxShadow1Color},-1.8rem -1.8rem  0px -12.5px ${item.boxShadow1Color}, 0 0 2rem #ccc`, zIndex: '10'}}>
                
                <div className="mx-md-5 mx-3 my-4 my-md-0">

                    <div className="text-head my-2 my-md-4">
                        {item.textHead}
                    </div>

                    <div className="about-text-content">{item.textContent}</div>
                    <div className="text-points my-md-3">
                        {item.textPoints.map((item1,index1)=>(
                        <div className="d-flex mt-lg-3 mt-md-3 mt-1" key={index1}>
                            <div className="about-hyphen col-2 col-sm-1 my-auto"><img src={hyphen} className="img-fluid" alt="Hyphen"/></div>

                            <div className="col-10 col-sm-11 about-text-content about-hyphen-text-content my-auto">{item1.point}</div>
                        </div>                        
                        ))}
                    </div>
                    
                </div>
                </div>
            ))}
            
        </div>
    </div>
  )
}

export default DrivesUs