import NavBar from '../components/NavBar';
import Hero from '../components/technologies/TechHero.js';
import FooterTheme from '../components/footer/FooterTheme';
import TechAllContent from '../components/technologies/TechAllContent';

import MetaTags from 'react-meta-tags';

import React from 'react'

function Services() {
  return (
    <div>
         <MetaTags>
              <title>Custom Website, Mobile App, Ecommerce, Mobile Game, Software, Product, UI/UX Design and Development Technologies - Humans Softtech</title>
              <meta name="description" content="Humans Softtech is the best Custom Website, Mobile App, Ecommerce, Mobile Game, Software, Product, UI/UX Design & Development, Technologies Consulting Services and Business Solutions Providing Agency in Ahmedabad, Gujarat, India." />
              <meta property="og:title" content="Custom Website, Mobile App, Ecommerce, Mobile Game, Software, Product, UI/UX Design and Development Technologies - Humans Softtech" />
              <meta property="og:image" content="./assets/homeimages/Humans.png" />
        </MetaTags>
        <NavBar/>
        <Hero/>
        <TechAllContent/>

        <FooterTheme/>      
    </div>
  )
}

export default Services