import React from 'react'
import "../../styles/career/careerHero.css";
import CareerStrings from "../../utils/strings/CareerStrings";
 

 
function CareerHero() {




  return (
    <>
      <div className="career-hero">
        <div className="d-flex color-overlay text-white justify-content-center align-items-center">
          <h1>
            Careers At Humans Softtech
          </h1>
        </div>
      </div>

      <div className="row  container-fluid px-4 px-md-5">
        <div className="col-12 mt-5 heading">Current Job Openings</div>
      </div>
      <div className="row justify-content-around container-fluid px-4 px-md-5 ">

      {CareerStrings.career.map((item, index) =>  (
         
       <div className="d-flex col-md-6  mt-4"> 
          <div className={'backbox d-flex  px-4 px-md-5  text-white ' + (index %2==0 ?'me-md-4':"ms-md-4")} style={{ backgroundColor: item.backbox }}>
            <div className="d-flex  flex-column  me-4 ">
              <div className="mt-5 firstLine">{item.tittle}</div>
              <div className="paragraph">
                <div className="mt-4">              
                  <span className="newspan">Expertise:</span> {item.expertise}
                </div>
                <div >        
                  <span className="newspan"> Experience:</span> {item.experience}
                </div>
                <div >
                  <span className="newspan">Openings:</span> {item.oppenings}
                </div>
                <div className="mb-5">
                    <span className="newspan">Location:</span> {item.location}
                </div>
              </div>

              <div className="mt-auto mb-5">
              <ApplyBtn/>
              </div>

            </div>
            </div>
          </div>
          
        
      ))}

      </div>      
  
    </>
  );
}

function ApplyBtn(){
  
  const [mouseEnter,setMouseEnter] = React.useState(false);
  return(
    <a href="#empty" className="linkto">
                <button className="btn btn-light py-lg-3 ps-lg-4 clickMe" onMouseEnter={()=>setMouseEnter(true)} onMouseLeave={()=>setMouseEnter(false)}>
                  Apply Now 
                  <img
                    src={`./assets/careerImages/careerVector${mouseEnter?"2":""}.png`}
                    className="ms-4 me-3 my-2 aero"
                    alt="error"
                  />
                  
                </button></a>
  )
}

export default CareerHero;
