import "../../styles/About.css";
import AboutStrings from "../../utils/strings/AboutStrings";

function QuoteOne() {
  return (
    <div className="py-4 py-md-5" style={{backgroundColor:"#FFDAA5"}}>
        <div className="container-fluid px-4 px-md-5" >
            <div className="mx-1 mx-lg-5 py-3 py-lg-5 quote-box" style={{backgroundColor:"white", borderRadius:"40px",border:"2px solid #01C9BD", Image:"linear-gradient(to right, #f1b1b1 , #82e6e8)"}}>
              <div className="row">
                <div className="col-md-1 col-2 pe-2 text-end "><img className="right-quote" src="./assets/aboutimages/upper-quotation.png" alt="Quote" srcset=""  /></div>
                  <div className="col-md-10 col-8 ">
                  <span className="quote ">  {AboutStrings.quoteSection.quoteOne} <img className="lower-quote" src="./assets/aboutimages/lower-quotation.png" alt="Quote" srcset="" /></span>
                  </div>
                <div className="col-md-1  col-2 row lower-quote align-items-end"> </div>
              </div>

              <div className="row pb-2">
                  <div className="col-md-1 col-2"></div>
                  <div className="col-md-10 col-8 mt-3 mt-lg-5">
                    <div className="quote-name ">{AboutStrings.quoteSection.nameOne}</div>
                    <div className="quote-position">{AboutStrings.quoteSection.positionOne}</div>
                  </div>
                  <div className="col-md-1 col-2"></div>
              </div>
            </div>
        </div>
    </div>
  )
}

export default QuoteOne