import React, { useState } from 'react'
import "../../styles/Hero.css";
import "@fontsource/open-sans"; // Defaults to weight 400.
import Strings from "../../utils/strings/HomeStrings";

export default function OurAreas() {
    const [Name, setName] = useState(Strings.lists[0].name);
    const [Lists, setLists] = useState(Strings.lists[0].list);
    const [link,setLink] = useState(Strings.lists[0].redirect);
   
    const setData = (name, list,link) => {
      setName(name);
      setLists(list);
      setLink(link)
    };

    // const [selectedName,setSelectedName] = React.useState("");
  
    return (
    <>

    {/* OUR AREAS OF EXPERTISE */}
    <div className="container-fluid px-4 px-md-5 custombg">
        <div className="row pt-4 pb-0 pt-lg-5 pb-lg-1">
          <div className="headoae col-6 align-self-end">
            <h3 className="headcss">Our Areas Of Expertise</h3>
          </div>
          <div className="col-6 viewjobbut">
            <div className="d-flex justify-content-end">
              <a href="/services">
                <button
                  type="button"
                  className="btn btn-lg btn-primary viewjob remove-default-bg"
                >
                  View All Services
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className="row oaeblock pt-4 pt-lg-5 pt-md-4" id='oaeblock'>
          <div className="col-6 pt-3 pt-lg-3">
            <div
              className="d-flex-column scroll-bar"
              style={{
                minHeight: "34%",
                maxHeight: "35%",
                overflowY: "scroll",
              }}

              id="scrolling"
            >
              {Strings.lists.map((item, index) => (
                <button
                  key={index}
                  type="button"
                  className={
                    "mt-3 mt-md-4 mt-lg-3 listbutton btn btn-lg btn-block " +
                    (item.name === Name ? "btn-grad" : "")
                  }
                  onClick={() => setData(item.name, item.list,item.redirect)}
                >
                  <img
                    src={`./assets/homeimages/ourareasofexp/${item.img}-${
                      !(item.name === Name) ? "black" : "white"
                    }.png`}
                    className="img-fluid me-3"
                    width={"25px"}
                    alt={item.name}
                  />
                  {item.name}
                </button>
              ))}
            </div>
          </div>

          <div className="col-6">
            <div className="d-flex justify-content-start">
              <div className="contentblock d-flex flex-column" id='contentblock'>
                <h3 className="servhead">{Name}</h3>
                <ul className="listscol">
                  {Lists.map((item, index) => (
                    <div className="row pt-lg-2">
                      <div className="hyphen col-1">—</div>
                      <div className="hyphen-lists col-11" key={index}>
                        {item}
                      </div>
                    </div>
                  ))}
                </ul>

                <div className="explorebutton mt-auto mb-3">
                  <button
                    type="button"
                    className="btn btn-primary btn-lg explore"
                    onClick={()=>window.location.href=link}
                 >
                  Explore  
                    <img
                      className="img-fluid ps-3 ps-md-4 ps-lg-4"
                      src="./assets/homeimages/ourareasofexp/explorearrow.png"
                      width={"50px"}
                      alt="Explore"
                    />

                  </button>
                </div>  
              </div>
            </div>
          </div>
          
        </div>

        <div className="oaemobblock">
          <div className="accordion" id="accordionExample">
            {Strings.lists.map((item, index) => (
              <Accordion item={item}
              // selectedName={selectedName} setSelectedName={setSelectedName}
               />
            ))}
          </div>
        </div>

        <div className="col-12 viewjobjugaad pb-4">
            <div className="d-flex justify-content-center">
              <a href="/services">
                <button
                  type="button"
                  className="btn btn-lg btn-primary viewjob remove-default-bg"
                >
                  View All Services
                </button>
              </a>
            </div>
          </div>
       
      </div>    
    </>
    )   
}

function Accordion({ item
  // ,selectedName,setSelectedName
 }) {
  const [open, setOpen] = React.useState(false);
  const [Name, setName] = useState(Strings.lists[0].name);
  const [link,setLink] = useState(Strings.lists[0].redirect);

  const setData = (link) => {
    setLink(link)
  };

  return (
    <div className="item">
      <div className="item-header" id="headingOne">
        <h2 className="mb-0">
          <button
            className="btn btn-link "
            type="button"
            // data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
            onClick={() => 
              // {setSelectedName(item.name);
              setOpen(!open)
              // if(item.name!==selectedName){setOpen(false)}
            }
          // }
            onLoad={() => setData(item.redirect)}
          >
            <div className="row d-flex">
              
              <div className="col-2">
                <img
                  src={`./assets/homeimages/ourareasofexp/${item.img}-${
                    !(item.name === Name) ? "black" : "black"
                  }.png`}
                  className="moblist me-3"
                  width={"35px"}
                  alt="Services"
                />
              </div>
              
              <div className="col-8">
                {item.name}
              </div>
              
              <div className="col-2">
                <img
                  src="./assets/homeimages/ourareasofexp/droparrow.png"
                  className="img-fluid arrow my-2"
                  alt="dropdown"
                  style={{ transform: `rotate(${open ? 180 : 0}deg)` }}
                />
              </div>

            </div>
          </button>
        </h2>
      </div>
      {open
      // && item.name === selectedName
      ? (
        <div id="collapseOne" className="collapse show">
          <div className="t-p">
            {item.list.map((item, index) => (
              <div className="row pt-lg-2" key={index}>
                <div className="hyphen col-1">—</div>
                <div className="hyphen-lists col-11" key={index}>
                  {item}
                </div>
                
              </div>
            ))}
            <div className="explorebutton mt-3">
                  <button
                    type="button"
                    className="btn btn-primary btn-lg mobexplore"
                    onClick={()=>window.location.href=link}
                 >
                  Explore  
                    <img
                      className="img-fluid ps-3 ps-md-4 ps-lg-4"
                      src="./assets/homeimages/ourareasofexp/explorearrow.png"
                      width={"40px"}
                      alt="Explore"
                    />
                  </button>
                </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}