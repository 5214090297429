import React from 'react'
import "../../styles/thankyou/thankyou.css"


export  default function ThanksHero() {

  return ( 

    <>
    <div>
        <div className="thankyou-hero">
          <div className="d-flex color-overlay text-white justify-content-center align-items-center">
            <h1>Thanks!</h1>
          </div>
        </div>

        <div className='row m-3 m-md-5'>
            <div className='col-12 thank-text mt-5 mb-5 text-center'> <h2>Thanks for getting in touch!
                 We will speak soon!</h2> </div>
        </div>
        </div>

    </>
  );
}

