import React from "react";
import "../../styles/footer/Footer.css";
import FormFooterTheme from './FormFooterTheme';
import LinkFooterTheme from "./LinkFooterTheme";

export default function FooterTheme() {
  return (
    <>
      <FormFooterTheme/>
      <LinkFooterTheme/>
    </>
  );
}
