import "../../styles/About.css"
import "../../styles/thankyou/thankyouhead.css"
import AboutStrings from "../../utils/strings/AboutStrings"

function Expertise() {
  return (
    <div style={{backgroundColor:"#FFDAA5"}}>
        <div className="container-fluid px-4  px-md-5">
            <div className="about-heading thankyouhead my-4">{AboutStrings.allHead.expertise}</div>
        </div>

        <div className="container-fluid px-4 justify-content-between row px-md-5 pb-4">
          {AboutStrings.expertiseSection.map((item,index) =>(
           
         
            <div className={"text-center mb-3 expertise-box col-md-4 col-lg-2 " 
            +(index%5===0?"me-lg-1 ":(index%5===4?"ms-lg-1 ":"mx-lg-1 ")) + (index%3===0?"me-1 ":(index%3===2?"ms-1 ":"mx-1 ")) } 
            style={{backgroundColor:item.color,borderRadius:"35px", }} onClick={()=>window.location.href=item.link}  >
                <img className="my-sm-4 my-3 expertise-image" src={item.image} alt={item.text} srcset=""/>
                <p className="text-center expertise-text mx-2 pb-sm-3" style={{fontWeight:400, color:'white'}}>{item.text}</p>
                
            </div>
         
          ))}
        </div>
        
    </div>
  )
}

export default Expertise