import NavBar from '../components/NavBar';
import Hero from '../components/home/Hero';
import FooterWhite from '../components/footer/FooterWhite'
import MetaTags from 'react-meta-tags';

function Home() {
  return (
    <div>
        <MetaTags>
              <title> Custom Website, Mobile App, Ecommerce, Mobile Game, Software, Product, UI/UX Design and Development Company - Humans Softtech</title>
              <meta name="description" content="Humans Softtech is the leading Custom Website, Mobile App, Ecommerce, Mobile Game, Software, Product, UI/UX Design & Development, Technologies Consulting Services and Business Solutions Providing Company in Ahmedabad, Gujarat, India." />
              <meta property="og:title" content="Custom Website, Mobile App, Ecommerce, Mobile Game, Software, Product, UI/UX Design and Development Company - Humans Softtech" />
              <meta property="og:image" content="./assets/homeimages/Humans.png" />
        </MetaTags>
        <NavBar/>
        <Hero/>
        <FooterWhite/>
    </div>
  )
}

export default Home