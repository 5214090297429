import React from 'react'
import '../../styles/contact/Contact.css'
import Constrings from '../../utils/strings/ContactStrings';

export default function ContactHero() {
  return (
    <>
        <div className="contact-hero">
                <div className="d-flex color-overlay text-white justify-content-center align-items-center">
                    <h1>Start Your Creative<br/>Digital Transformation Project</h1>
                </div>
            </div>

        {/* Why We Do What We Do? */}
        
        <div className="container-fluid px-4 px-md-5 whywedo">
            <div className="row pb-lg-5 pb-md-5 pb-2">

                <div className="col-12 col-lg-6 col-md-6 pt-lg-5 pb-lg-4 pt-md-4 pt-3 pb-md-4"> 
                    <div className="d-flex align-items-center justify-content-center">
                        <img src="./assets/contactimages/hands.png" className="img-fluid handshake" width={"550px"} alt="Contact Image"/>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-12 handshaketext p-xxl-0 p-xl-2 p-lg-3 pt-md-3 ps-md-4 pt-3 m-auto">
                    {Constrings.greet.map((item,index)=>(
                        <p>{item}</p>
                    ))}
                </div>
            </div>
        </div>

    </>
  );
}
