
var allContent = [
    {
      flag:false,
      head: "UI/UX Design Tools",
      content:
        "We focus on designing engaging UI/UX & Graphics with well-thought-out behaviors to ensure enriched experiences. Here is a list of UI/UX & Graphics design tools, software, and technologies we use.",
      tablets: [
        {
          link: "./assets/technologiesimages/icons/UI_UX_Design_Tools/figma.png",
          color: "#4CC0AD",
          text: "Figma",
        },
        {
          link: "./assets/technologiesimages/icons/UI_UX_Design_Tools/sketch.png",
          color: "#082340",
          text: "Sketch",
        },
        {
          link: "./assets/technologiesimages/icons/UI_UX_Design_Tools/adobe-xd.png",
          color: "#F58C76",
          text: "Adobe XD",
        },
       
        {
          link: "./assets/technologiesimages/icons/UI_UX_Design_Tools/adobe-illustrator.png",
          color: "#F15B40",
          text: "Adobe Illustrator",
        },
        {
          link: "./assets/technologiesimages/icons/UI_UX_Design_Tools/photoshop.png",
          color: "#F68B28",
          text: "Adobe Photoshop",
        },
        {
          link: "./assets/technologiesimages/icons/UI_UX_Design_Tools/invision.png",
          color: "#5F8AC7",
          text: "InVision Studio",
        }
      ],
    },
    {
      flag:true,
      head: "Website Development Technologies",
      content:
        "We develop custom websites using the best CMS platforms that drive user engagement, conversions, and sales. Here is a list of low-code and no-code CMS website development platforms, tools, software, and technologies we use.",
      tablets: [
        {
          link: "./assets/technologiesimages/icons/Website_Development_Technologies/wordpress.png",
          color: "#4CC0AD",
          text: "WordPress",
        },
        {
          link: "./assets/technologiesimages/icons/Website_Development_Technologies/elementor.png",
          color: "#082340",
          text: "Elementor",
        },
        {
          link: "./assets/technologiesimages/icons/Website_Development_Technologies/wpbakery.png",
          color: "#F58C76",
          text: "WPBakery",
        },
        {
          link: "./assets/technologiesimages/icons/Website_Development_Technologies/drupal.png",
          color: "#7C4934",
          text: "Drupal",
        },
        {
          link: "./assets/technologiesimages/icons/Website_Development_Technologies/joomla.png",
          color: "#F15B40",
          text: "Joomla",
        },
        {
          link: "./assets/technologiesimages/icons/Website_Development_Technologies/dotnetnuke.png",
          color: "#F68B28",
          text: "DotNetNuke",
        },
        {
          link: "./assets/technologiesimages/icons/Website_Development_Technologies/Kentico.png",
          color: "#5F8AC7",
          text: "Kentico",
        },
        {
          link: "./assets/technologiesimages/icons/Website_Development_Technologies/Umbraco.png",
          color: "#00A692",
          text: "Umbraco",
        },
        {
          link: "./assets/technologiesimages/icons/Website_Development_Technologies/Sitecore.png",
          color: "#9FC131",
          text: "Sitecore",
        },
        {
          link: "./assets/technologiesimages/icons/Website_Development_Technologies/dotcms.png",
          color: "#E15858",
          text: "dotCMS",
        },
        {
          link: "./assets/technologiesimages/icons/Website_Development_Technologies/hubspot.png",
          color: "#0099DD",
          text: "HubSpot",
        },
        {
          link: "./assets/technologiesimages/icons/Website_Development_Technologies/webflow.png",
          color: "#646464",
          text: "Webflow",
        },
        {
          link: "./assets/technologiesimages/icons/Website_Development_Technologies/wix.png",
          color: "#026E81",
          text: "Wix",
        },
        {
          link: "./assets/technologiesimages/icons/Website_Development_Technologies/squarespace.png",
          color: "#8C1F28",
          text: "Squarespace",
        },
        {
          link: "./assets/technologiesimages/icons/Website_Development_Technologies/Liferay.png",
          color: "#00ABBD",
          text: "Liferay",
        }
      ],
    },
    {
      flag:false,
      head: "Ecommerce Development Technologies",
      content:
        "We select the best e-commerce website development platform that comes with the features you need to launch your online store quickly and drive more sales. Here is a list of eCommerce development platforms, tools, software, and technologies we use.",
      tablets: [
        {
          link: "./assets/technologiesimages/icons/Ecommerce_Development_Technologies/woocommerce.png",
          color: "#4CC0AD",
          text: "WooCommerce",
        },
        {
          link: "./assets/technologiesimages/icons/Ecommerce_Development_Technologies/shopify.png",
          color: "#082340",
          text: "Shopify",
        },
        {
          link: "./assets/technologiesimages/icons/Ecommerce_Development_Technologies/magento.png",
          color: "#F58C76",
          text: "Magento",
        },
        {
          link: "./assets/technologiesimages/icons/Ecommerce_Development_Technologies/big-commerce.png",
          color: "#7C4934",
          text: "BigCommerce",
        }
      ],
    },
    {
      flag:true,
      head: "Mobile App Development Technologies",
      content:
        "We build rich and interactive mobile apps that are maintainable, scalable, and carefully customized to your business requirements. Here is a list of mobile app development frameworks, tools, programming languages, and technologies we use.",
      tablets: [
        {
          link: "./assets/technologiesimages/icons/Mobile_App_Development_Technologies/ios.png",
          color: "#4CC0AD",
          text: "iOS (Objective-C, Swift)",
        },
        {
          link: "./assets/technologiesimages/icons/Mobile_App_Development_Technologies/android.png",
          color: "#082340",
          text: "Android (Java, Kotlin)",
        },
        {
          link: "./assets/technologiesimages/icons/Mobile_App_Development_Technologies/react_native.png",
          color: "#F58C76",
          text: "React Native",
        },
        {
          link: "./assets/technologiesimages/icons/Mobile_App_Development_Technologies/flutter.png",
          color: "#7C4934",
          text: "Flutter",
        },
        {
          link: "./assets/technologiesimages/icons/Mobile_App_Development_Technologies/xamarin.png",
          color: "#F15B40",
          text: "Xamarin",
        },
        {
          link: "./assets/technologiesimages/icons/Mobile_App_Development_Technologies/ionic.png",
          color: "#F68B28",
          text: "Ionic",
        }
      ],
    },
    {
      flag:false,
      head: "Mobile Game Development Technologies",
      content:
        "We develop great mobile games for our clients that as many people as possible play for years and that are remembered forever. Here is a list of mobile game development software, platforms, tools, frameworks, and technologies we use.",
      tablets: [
        {
          link: "./assets/technologiesimages/icons/Mobile_Game_Development_Technologies/unity.png",
          color: "#4CC0AD",
          text: "Unity",
        },
        {
          link: "./assets/technologiesimages/icons/Mobile_Game_Development_Technologies/unreal-engine.png",
          color: "#082340",
          text: "Unreal Engine",
        },
        {
          link: "./assets/technologiesimages/icons/Mobile_Game_Development_Technologies/react-native.png",
          color: "#F58C76",
          text: "React Native",
        },
        {
          link: "./assets/technologiesimages/icons/Mobile_Game_Development_Technologies/flutter.png",
          color: "#7C4934",
          text: "Flutter",
        },
        {
          link: "./assets/technologiesimages/icons/Mobile_Game_Development_Technologies/ios.png",
          color: "#F15B40",
          text: "iOS Game",
        },
        {
          link: "./assets/technologiesimages/icons/Mobile_Game_Development_Technologies/android.png",
          color: "#F68B28",
          text: "Android Game",
        }
      ],
    },
    {
      flag:true,
      head: "Frontend Development Technologies",
      content:
        "We have multi-talented front-end developers with extensive knowledge and experience to build the front-end for a custom website, CMS website, ecommerce website, web portal, and web application with smooth user experience. Here is a list of front-end development programming languages, frameworks, and technologies we use.",
      tablets: [
        {
          link: "./assets/technologiesimages/icons/Frontend_Development_Technologies/html.png",
          color: "#4CC0AD",
          text: "HTML",
        },
        {
          link: "./assets/technologiesimages/icons/Frontend_Development_Technologies/css.png",
          color: "#082340",
          text: "CSS",
        },
        {
          link: "./assets/technologiesimages/icons/Frontend_Development_Technologies/bootstrap.png",
          color: "#F58C76",
          text: "Bootstrap",
        },
        {
          link: "./assets/technologiesimages/icons/Frontend_Development_Technologies/javascript.png",
          color: "#7C4934",
          text: "JavaScript",
        },
        {
          link: "./assets/technologiesimages/icons/Frontend_Development_Technologies/jquery.png",
          color: "#F15B40",
          text: "jQuery",
        },
        {
          link: "./assets/technologiesimages/icons/Frontend_Development_Technologies/react.png",
          color: "#F68B28",
          text: "React",
        },
        {
          link: "./assets/technologiesimages/icons/Frontend_Development_Technologies/angular.png",
          color: "#5F8AC7",
          text: "Angular",
        },
        {
          link: "./assets/technologiesimages/icons/Frontend_Development_Technologies/vue.png",
          color: "#00A692",
          text: "Vue.js",
        },
        {
          link: "./assets/technologiesimages/icons/Frontend_Development_Technologies/typescript.png",
          color: "#9FC131",
          text: "TypeScript",
        },
        {
          link: "./assets/technologiesimages/icons/Frontend_Development_Technologies/backbone.png",
          color: "#E15858",
          text: "Backbone.js",
        }
      ],
    },
    {
      flag:false,
      head: "Backend Development Technologies",
      content:
        "Driven by agile principles and the industry's best practices, we possess extensive experience in back-end development for websites, web applications, mobile apps, and mobile games with the highest quality. Here is a list of backend development programming languages, frameworks, and technologies we use.",
      tablets: [
        {
          link: "./assets/technologiesimages/icons/Backend_Development_Technologies/dotNet.png",
          color: "#4CC0AD",
          text: "Microsoft .NET",
        },
        {
          link: "./assets/technologiesimages/icons/Backend_Development_Technologies/java.png",
          color: "#082340",
          text: "Java",
        },
        {
          link: "./assets/technologiesimages/icons/Backend_Development_Technologies/php.png",
          color: "#F58C76",
          text: "PHP (Laravel)",
        },
        {
          link: "./assets/technologiesimages/icons/Backend_Development_Technologies/python.png",
          color: "#7C4934",
          text: "Python (Django, Flask)",
        },
        {
          link: "./assets/technologiesimages/icons/Backend_Development_Technologies/golang.png",
          color: "#F15B40",
          text: "Golang",
        },
        {
          link: "./assets/technologiesimages/icons/Backend_Development_Technologies/ruby_on_rails.png",
          color: "#F68B28",
          text: "Ruby on Rails",
        },
        {
          link: "./assets/technologiesimages/icons/Backend_Development_Technologies/javascript.png",
          color: "#5F8AC7",
          text: "JavaScript (Node.js, Express.js, Meteor.js)",
        }
      ],
    },
    {
      flag:true,
      head: "Full Stack Development Technologies",
      content:
        "From the front-end to back-end programming, each stage of development requires a unique set of skills. If you are looking for a full-stack development team that can handle all aspects of your project, consider working with our full-stack developers. Our full-stack developers have the technical expertise and experience to create custom solutions that deliver results. Here is a list of full-stack development frameworks, programming languages, and technologies we use.",
      tablets: [
        {
          link: "./assets/technologiesimages/icons/FullStack_Development_Technologies/MEAN.png",
          color: "#4CC0AD",
          text: "MEAN Stack",
        },
        {
          link: "./assets/technologiesimages/icons/FullStack_Development_Technologies/MERN.png",
          color: "#082340",
          text: "MERN Stack",
        },
        {
          link: "./assets/technologiesimages/icons/FullStack_Development_Technologies/MEVN.png",
          color: "#F58C76",
          text: "MEVN Stack",
        },
        {
          link: "./assets/technologiesimages/icons/FullStack_Development_Technologies/LAMP.png",
          color: "#7C4934",
          text: "LAMP Stack",
        },
        {
          link: "./assets/technologiesimages/icons/FullStack_Development_Technologies/Jamstack.png",
          color: "#F15B40",
          text: "Jamstack",
        },
    
      ],
    },
    { 
      flag:false,
      head: "Database Technologies",
      content:
        "We offer exhaustive database management services to our clients, enabling them to implement the best-suited solutions for their varied requirements. Here is a list of database software, tools, and technologies we use.",
      tablets: [
        {
          link: "./assets/technologiesimages/icons/Database_Technologies/mysql.png",
          color: "#4CC0AD",
          text: "MySQL",
        },
        {
          link: "./assets/technologiesimages/icons/Database_Technologies/postgresql.png",
          color: "#082340",
          text: "PostgreSQL",
        },
        {
          link: "./assets/technologiesimages/icons/Database_Technologies/SQLite.png",
          color: "#F58C76",
          text: "SQLite",
        },
        {
          link: "./assets/technologiesimages/icons/Database_Technologies/mongodb.png",
          color: "#7C4934",
          text: "MongoDB",
        },
        {
          link: "./assets/technologiesimages/icons/Database_Technologies/firebase.png",
          color: "#F15B40",
          text: "Firebase",
        },
        {
          link: "./assets/technologiesimages/icons/Database_Technologies/Amazon_RDS.png",
          color: "#F68B28",
          text: "Amazon RDS",
        },
        {
          link: "./assets/technologiesimages/icons/Database_Technologies/microsql.png",
          color: "#5F8AC7",
          text: "Microsoft SQL Server",
        },
        {
          link: "./assets/technologiesimages/icons/Database_Technologies/Amazon_DynamoDB.png",
          color: "#00A692",
          text: "Amazon DynamoDB",
        },
        {
          link: "./assets/technologiesimages/icons/Database_Technologies/azure_sql_database.png",
          color: "#9FC131",
          text: "Microsoft Azure SQL Database",
        },
      ],
    },
    { 
      flag:true,
      head: "Cloud Computing Technologies",
      content:
        "We work with all the major cloud providers through dedicated hyperscaler business groups to offer full-stack skills and capabilities. Together, we assemble skilled teams, software, and platforms to enable secure cloud computing solutions that accelerate growth in your business.",
      tablets: [
        {
          link: "./assets/technologiesimages/icons/Cloud_Computing_Technologies/gcp.png",
          color: "#4CC0AD",
          text: "Google Cloud Platform (GCP)",
        },
        {
          link: "./assets/technologiesimages/icons/Cloud_Computing_Technologies/aws.png",
          color: "#082340",
          text: "Amazon Web Services (AWS)",
        },
        {
          link: "./assets/technologiesimages/icons/Cloud_Computing_Technologies/azure.png",
          color: "#F58C76",
          text: "Microsoft Azure",
        }
      ],
    },
    { 
      flag:false,
      head: "DevOps Tools",
      content:
        "We enable enterprises to become agile DevOps organizations leveraging the industry’s best practices, the right skills, expertise, and the latest technologies.",
      tablets: [
        {
          link: "./assets/technologiesimages/icons/DevOps_Tools/bitbucket.png",
          color: "#4CC0AD",
          text: "Bitbucket",
        },
        {
          link: "./assets/technologiesimages/icons/DevOps_Tools/github.png",
          color: "#082340",
          text: "GitHub",
        },
        {
          link: "./assets/technologiesimages/icons/DevOps_Tools/gitlab.png",
          color: "#F58C76",
          text: "GitLab",
        },
        {
          link: "./assets/technologiesimages/icons/DevOps_Tools/ant.png",
          color: "#7C4934",
          text: "Apache Ant",
        },
        {
          link: "./assets/technologiesimages/icons/DevOps_Tools/docker.png",
          color: "#F15B40",
          text: "Docker",
        },
        {
          link: "./assets/technologiesimages/icons/DevOps_Tools/Gradle.png",
          color: "#F68B28",
          text: "Gradle",
        },
        {
          link: "./assets/technologiesimages/icons/DevOps_Tools/apache.png",
          color: "#5F8AC7",
          text: "Apache Maven",
        },
        {
          link: "./assets/technologiesimages/icons/DevOps_Tools/xcode.png",
          color: "#00A692",
          text: "Xcode",
        },
        {
          link: "./assets/technologiesimages/icons/DevOps_Tools/Apache_JMeter.png",
          color: "#9FC131",
          text: "Apache JMeter",
        },
        {
          link: "./assets/technologiesimages/icons/DevOps_Tools/BlazeMeter.png",
          color: "#E15858",
          text: "BlazeMeter",
        },
        {
          link: "./assets/technologiesimages/icons/DevOps_Tools/burpsuite.png",
          color: "#0099DD",
          text: "Burp Suite",
        },
        {
          link: "./assets/technologiesimages/icons/DevOps_Tools/jenkins.png",
          color: "#646464",
          text: "Jenkins",
        },
        {
          link: "./assets/technologiesimages/icons/DevOps_Tools/selenium.png",
          color: "#026E81",
          text: "Selenium",
        },
        {
          link: "./assets/technologiesimages/icons/DevOps_Tools/Wireshark.png",
          color: "#8C1F28",
          text: "Wireshark",
        },
        {
          link: "./assets/technologiesimages/icons/DevOps_Tools/Azure_Pipelines.png",
          color: "#00ABBD",
          text: "Azure Pipelines",
        },
        {
          link: "./assets/technologiesimages/icons/DevOps_Tools/Codefresh.png",
          color: "#32286F",
          text: "Codefresh",
        },
        {
          link: "./assets/technologiesimages/icons/DevOps_Tools/git.png",
          color: "#AA570B",
          text: "Git",
        },
        {
          link: "./assets/technologiesimages/icons/DevOps_Tools/AWS.png",
          color: "#8C1F28",
          text: "AWS",
        },
        {
          link: "./assets/technologiesimages/icons/DevOps_Tools/azure.png",
          color: "#B4531C",
          text: "Azure",
        },
        {
          link: "./assets/technologiesimages/icons/DevOps_Tools/Kubernetes.png",
          color: "#082340",
          text: "Kubernetes",
        },
        {
          link: "./assets/technologiesimages/icons/DevOps_Tools/Ansible.png",
          color: "#44803F",
          text: "Ansible",
        },
        {
          link: "./assets/technologiesimages/icons/DevOps_Tools/Progress_Chef.png",
          color: "#1E3758",
          text: "Progress Chef",
        },
        {
          link: "./assets/technologiesimages/icons/DevOps_Tools/puppet.png",
          color: "#3F4545",
          text: "Puppet",
        },
        {
          link: "./assets/technologiesimages/icons/DevOps_Tools/Datadog.png",
          color: "#BF2604",
          text: "Datadog",
        },
        {
          link: "./assets/technologiesimages/icons/DevOps_Tools/Nagios.png",
          color: "#6A6864",
          text: "Nagios",
        },
        {
          link: "./assets/technologiesimages/icons/DevOps_Tools/Splunk.png",
          color: "#091E3A",
          text: "Splunk",
        },
        {
          link: "./assets/technologiesimages/icons/DevOps_Tools/confluence.png",
          color: "#146152",
          text: "Confluence",
        },
        {
          link: "./assets/technologiesimages/icons/DevOps_Tools/jira.png",
          color: "#0214BC",
          text: "Jira",
        },
      ],
    },
    { 
      flag:true,
      head: "Software Testing Tools",
      content:
        "We are committed to swiftly delivering quality applications and products. We help our clients by ensuring a seamless and continuous agile delivery that surpasses every digital quality benchmark. Our QA testing team of experts leverages the latest software testing tools and technologies.",
      tablets: [
        {
          link: "./assets/technologiesimages/icons/Software_Testing_Tools/selenium.png",
          color: "#4CC0AD",
          text: " Selenium",
        },
        {
          link: "./assets/technologiesimages/icons/Software_Testing_Tools/Katalon.png",
          color: "#082340",
          text: " Katalon",
        },
        {
          link: "./assets/technologiesimages/icons/Software_Testing_Tools/appium.png",
          color: "#F58C76",
          text: "Appium",
        },
        {
          link: "./assets/technologiesimages/icons/Software_Testing_Tools/SoapUI.png",
          color: "#7C4934",
          text: "SoapUI",
        },
        {
          link: "./assets/technologiesimages/icons/Software_Testing_Tools/postman.png",
          color: "#F15B40",
          text: "Postman",
        },
        {
          link: "./assets/technologiesimages/icons/Software_Testing_Tools/BlazeMeter.png",
          color: "#F68B28",
          text: "BlazeMeter",
        },
        {
          link: "./assets/technologiesimages/icons/Software_Testing_Tools/Trello.png",
          color: "#5F8AC7",
          text: "Trello",
        },
        {
          link: "./assets/technologiesimages/icons/Software_Testing_Tools/SpecFlow.png",
          color: "#00A692",
          text: "SpecFlow",
        },
        {
          link: "./assets/technologiesimages/icons/Software_Testing_Tools/BrowserStack.png",
          color: "#9FC131",
          text: "BrowserStack",
        },
        {
          link: "./assets/technologiesimages/icons/Software_Testing_Tools/Perfecto.png",
          color: "#E15858",
          text: "Perfecto",
        },
        {
          link: "./assets/technologiesimages/icons/Software_Testing_Tools/AWS-Device-Farm.png",
          color: "#0099DD",
          text: "AWS Device Farm",
        }
      ],
    },
    
    {
      flag:false,
      head: "Data and Analytics Tools",
      content:
        "We help our clients devise data and analytics solutions to make informed and insightful business decisions, empowering them to be self-sufficient in the digital age. Here are some of the business intelligence, data and analytics tools we rely on.",
      tablets: [
        {
          link: "./assets/technologiesimages/icons/Data_and_Analytics_Tools/Talend.png",
          color: "#4CC0AD",
          text: "Talend",
        },
        {
          link: "./assets/technologiesimages/icons/Data_and_Analytics_Tools/Qlik.png",
          color: "#082340",
          text: "Qlik",
        },
        {
          link: "./assets/technologiesimages/icons/Data_and_Analytics_Tools/tableau.png",
          color: "#F58C76",
          text: "Tableau",
        },
        {
          link: "./assets/technologiesimages/icons/Data_and_Analytics_Tools/looker.png",
          color: "#7C4934",
          text: "Looker",
        },
        {
          link: "./assets/technologiesimages/icons/Data_and_Analytics_Tools/ThoughtSpot.png",
          color: "#F15B40",
          text: "ThoughtSpot",
        },
        {
          link: "./assets/technologiesimages/icons/Data_and_Analytics_Tools/zoho.png",
          color: "#F68B28",
          text: "Zoho Analytics",
        },
        {
          link: "./assets/technologiesimages/icons/Data_and_Analytics_Tools/ibmanalytics.png",
          color: "#5F8AC7",
          text: "IBM Analytics",
        },
        {
          link: "./assets/technologiesimages/icons/Data_and_Analytics_Tools/powerbi.png",
          color: "#00A692",
          text: "Microsoft Power BI",
        }
      ],
    },

  ];






const TechnologiesStrings = {
    allContent
}

export default TechnologiesStrings;