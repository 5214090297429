import React, { useState } from "react";
import "../../styles/Hero.css";
import "@fontsource/open-sans"; // Defaults to weight 400.
import Strings from "../../utils/strings/HomeStrings";

export default function JobOpen() {
  return (
    <>
        <div className="container-fluid px-4 px-md-5 currentjob">
            <div className="row pt-4 pt-lg-5">
                
                <div className="col-6 human align-self-end">
                    <h3 className="headcss">Current Job Openings</h3>
                </div>

                <div className="col-6 viewjobbut">
                    <div className="d-flex justify-content-end">
                        <a href="/careers">
                            <button
                                type="button"
                                className="btn btn-lg btn-primary viewjob remove-default-bg"
                            >
                            {" "}
                            View All Job Openings{" "}
                            </button>
                        </a>
                    </div>
                </div>
            </div>

            <div className="row pb-5 pb-lg-5 pb-md-4">
                <div className="col-12 jobopencol">
                    {Strings.jobopen.map((item, index) => (
                    <div className="jobslists">
                        <div>{item.position}</div>
                    </div>
                    ))}
                </div>

                <div className="explorebutton d-flex align-items-start mt-5">
                    <button type="button" className="btn btn-primary btn-lg explore">
                    <div className="row">

                        <a href="/careers" className="text-decoration-none text-white">
                        <div className="d-flex justify-content-evenly align-items-center">        
                            <div className="col-12">Apply Now</div>
                            <div className="col-6">
                                <img
                                className="img-fluid"
                                src="./assets/homeimages/ourareasofexp/explorearrow.png"
                                width={"20px"}
                                alt="Explore"
                                />
                            </div>
                        </div>
                        </a>
                    </div>
                    </button>
                </div>

                <div className="col-12 viewjobjugaad">
                    <div className="d-flex justify-content-center">
                    <a href="/careers">
                        <button
                        type="button"
                        className="btn btn-lg btn-primary viewjob remove-default-bg"
                        >
                        {" "}
                        View All Job Openings{" "}
                        </button>
                    </a>
                    </div>
                </div>
            </div>
      </div>
    </>
  )
}
