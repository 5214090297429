import React from 'react'
import NavBar from '../components/NavBar'
import FooterTheme from '../components/footer/FooterTheme'
import PrivacyHero from '../components/privacypolicy/privacyHero'

import MetaTags from 'react-meta-tags';

function PrivacyPolicy() {
  return (
    <div>

        <MetaTags>
              <title>Privacy Policy - Humans Softtech</title>
              <meta property="og:title" content="Privacy Policy - Humans Softtech" />
              <meta property="og:image" content="./assets/homeimages/Humans.png" />
        </MetaTags>
        <NavBar/>
        <PrivacyHero/>
        <FooterTheme/>
    </div>
  )
}

export default PrivacyPolicy