const career = [
    {
      backbox: " #663344",
      tittle: "UI/UX Designer",
      expertise:
        "Figma, Sketch,Adobe XD,  Illustrator, Photoshop, InVision Studio",
      experience: "3+ Years",
      oppenings: "11",
      location: " Ahmedabad",
    },
    {
      backbox: "#7B6D8E",
      tittle: "Website Developer",
      expertise:
        "WordPress, Drupal, Joomla, DotNetNuke, Kentico, Umbraco, Sitecore, dotCMS, HubSpot, Webflow, Wix, Squarespace, Liferay",
      experience: "3+ Years",
      oppenings: "13",
      location: " Ahmedabad",
    },
    {
      backbox: "#0D486A",
      tittle: "Ecommerce Developer",
      expertise:
        "WooCommerce, Shopify, Magento, BigCommerce",
      experience: "3+ Years",
      oppenings: "8",
      location: " Ahmedabad",
    },
    {
      backbox: "#255152",
      tittle: "Mobile App Developer",
      expertise:
        "iOS (Objective-C, Swift), Android (Java, Kotlin), React Native, Flutter, Xamarin, Ionic",
      experience: "3+ Years",
      oppenings: "12",
      location: " Ahmedabad",
    },
    {
      backbox: "#4C797B",
      tittle: "Mobile Game Developer",
      expertise:
        "Unity, Unreal Engine, iOS (Objective-C, Swift, SpriteKit), Android (Java, Kotlin, Android Game Development Kit) ",
      experience: "3+ Years",
      oppenings: "8",
      location: " Ahmedabad",
    },
    {
      backbox: "#CB544B",
      tittle: "Frontend Developer",
      expertise:
        "HTML, CSS, Bootstrap, JavaScript, jQuery, React, Angular, Vue.js, TypeScript, Backbone.js",
      experience: "3+ Years",
      oppenings: "10",
      location: " Ahmedabad",
    },
    {
      backbox: "#293241",
      tittle: "Backend Developer",
      expertise:
        "Microsoft .NET, Java, PHP (Laravel), Python (Django, Flask), Golang, Ruby on Rails, JavaScript (Node.js, Express.js, Meteor.js)",
      experience: "3+ Years",
      oppenings: "12",
      location: " Ahmedabad",
    },
    {
      backbox: "#3D5A80",
      tittle: "Full Stack Developer",
      expertise:
        "MEAN Stack (MongoDB, Express.js, Angular, Node.js), MERN Stack (MongoDB, Express.js, React, Node.js), MEVN Stack (MongoDB, Express.js, Vue.js, Node.js), LAMP Stack (Linux, Apache, MySQL, PHP)",
      experience: "3+ Years",
      oppenings: "11",
      location: " Ahmedabad",
    },
    {
      backbox: "#025940",
      tittle: "Database Administrator",
      expertise:
        "MySQL, PostgreSQL, SQLite, MongoDB, Firebase, Amazon RDS, Microsoft SQL Server, Amazon DynamoDB, Microsoft Azure SQL Database",
      experience: "3+ Years",
      oppenings: "6",
      location: " Ahmedabad",
    },
    {
      backbox: "#BC3665",
      tittle: "Cloud Engineer",
      expertise:
        "Google Cloud Platform (GCP), Amazon Web Services (AWS), Microsoft Azure",
      experience: "3+ Years",
      oppenings: "4",
      location: " Ahmedabad",
    },
    {
      backbox: "#A66FA2",
      tittle: "DevOps Engineer",
      expertise:
        "Bitbucket, GitHub, GitLab, Apache Ant, Docker, Gradle, Apache Maven, Apache JMeter, Burp Suite, Jenkins, and more",
      experience: "3+ Years",
      oppenings: "4",
      location: " Ahmedabad",
    },
    {
      backbox: "#337AA6",
      tittle: "Software Testing Engineer",
      expertise:
        "Selenium, Katalon, Appium, SoapUI, Postman, BlazeMeter, Trello, SpecFlow, BrowserStack, Perfecto, AWS Device Farm",
      experience: "3+ Years",
      oppenings: "8",
      location: " Ahmedabad",
    },
    {
      backbox: "#2A9D8F",
      tittle: "Data Scientist",
      expertise:
        "Talend, Qlik, Tableau, Looker, ThoughtSpot, Zoho Analytics, IBM Analytics, Microsoft Power BI",
      experience: "3+ Years",
      oppenings: "4",
      location: " Ahmedabad",
    },
    {
      backbox: "#E76F51",
      tittle: "Emerging Technologies Expert",
      expertise:
        "Metaverse, AR, VR, MR, XR, Web3, Blockchain, AI, ML, Wearables, IoT, RPA, and more",
      experience: "3+ Years",
      oppenings: "7",
      location: " Ahmedabad",
    },
    {
      backbox: "#28363D",
      tittle: "Project Manager",
      expertise:
        "Project delivery management, project management tools, team management, time management, project estimation, project planning, project scheduling, cost management, clients communication, and more",
      experience: "3+ Years",
      oppenings: "5",
      location: " Ahmedabad",
    },
    {
      backbox: "#658B6F",
      tittle: "Business Development Executive",
      expertise:
        "Generate and close the IT services leads through market research, cold calling, bidding, social networking, and other techniques. IT services sales, project estimation, project documentation, clients communication, and more",
      experience: "3+ Years",
      oppenings: "14",
      location: " Ahmedabad",
    },
    {
      backbox: "#894329",
      tittle: "Content Writer",
      expertise:
        "Excellent writing & grammar skills, Adaptable writing voice that can match different styles/tones, SEO, Sales & Marketing skills, Research, Blogs & Articles Writing, Website Content Writing, Social Media Content Writing, Press Release Writing, Book Writing, etc.",
      experience: "3+ Years",
      oppenings: "5",
      location: " Ahmedabad",
    },
    {
      backbox: "#12727E",
      tittle: "Digital Marketing Executive",
      expertise:
        "SEO, SEM, PPC, Paid Advertising, ASO, Social Media Marketing, Influencer Marketing, Email Marketing, Content Marketing",
      experience: "3+ Years",
      oppenings: "6",
      location: " Ahmedabad",
    },
    {
      backbox: "#028B73",
      tittle: "HR Recruiter",
      expertise:
        "Talent acquisition and recruitment, Recruiting operations, Social Media, Networking, Scheduling interviews, Understanding technology, and more ",
      experience: "3+ Years",
      oppenings: "5",
      location: " Ahmedabad",
    },
  ];

  const CareerStrings = {
    career
}

export default CareerStrings;
