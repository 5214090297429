import React from "react";
import "../../styles/footer/Footer.css";

export default function LinkFooterTheme() {
  return (
    <>  
        <div className="container-fluid px-4 px-md-5 py-2 whitefootbg">
        <div className="mt-lg-4 mt-md-4 mt-2">
        <div className="kartik2 d-flex justify-content-between">
            <div className="d-flex ">

                    <div className="usefullinks">
                        <p className="fw-bold">Useful Links</p>
                    </div>

                    <div className="arrow align-self-start">
                        <img src="./assets/footarrow.png" height={"10px"} width={"8px"} className="footlink"/>
                    </div>
            
                    <div className="uselinks">
                        <div className="d-flex grid-container justify-space-end">

                                <div className="footmenu"><a href="services">Services</a></div>                 
                                <div className="footmenu"><a href="technologies">Technologies</a></div>
                                <div className="footmenu"><a href="projects">Projects</a></div>
                                <div className="footmenu"><a href="about">About</a></div>
                                <div className="footmenu"><a href="careers">Careers</a></div>
                                <div className="footmenu"><a href="contact">Contact</a></div>
                        </div>
                    </div>

            </div>

            <div className="terms">
                <p className="uselinks"><a href="/terms-of-use">Terms of Use</a> | <a href="/privacy-policy">Privacy Policy</a></p>
            </div>
            </div>
        </div>

          <div className="mt-4">
          <div className="stay-tuned d-flex justify-content-between">
            <div className="d-flex">
                <div className="stay my-1">
                    <p className="fw-bold">Stay Tuned</p>
                </div>

                <div className="arrow my-1 mx-2 align-self-start">
                    <img src="./assets/footarrow.png" height={"10px"} width={"8px"} className="footlink"/>
                </div>

          <div className="staylogos">
              <div className="d-flex img-container justify-space-end">
                  <div className="footlink"> <a href="https://www.linkedin.com/company/humans-softtech/"><img src="./assets/footicons/linkedin.png" width={"40px"} alt="LinkedIn" /></a> </div>
                  <div className="footlink"> <a href="https://www.facebook.com/humanssofttech"><img src="./assets/footicons/facebook.png" width={"40px"} alt="Facebook" /></a> </div>
                  <div className="footlink"> <a href="https://www.instagram.com/humanssofttech/"><img src="./assets/footicons/instagram.png" width={"40px"} alt="Instagram" /></a> </div>
                  <div className="footlink"> <a href="https://twitter.com/humanssofttech"><img src="./assets/footicons/twitter.png" width={"40px"} alt="Twitter"/></a></div>
                  <div className="footlink"> <a href="https://www.youtube.com/@humanssofttech"><img src="./assets/footicons/youtube.png" width={"40px"} alt="YouTube" /></a></div>     
                  <div className="footlink"> <a href="#"><img src="./assets/footicons/github.png" width={"40px"} alt="GitHub"/></a></div>
                  <div className="footlink"> <a href="#"><img src="./assets/footicons/dribble.png" width={"40x"} alt="Dribbble"/></a></div>
                  <div className="footlink"> <a href="#"><img src="./assets/footicons/behance.png" width={"40px"} alt="Behance"/></a></div>
              </div>
          </div>


          </div>
          
          </div>
            <div className="jugaad mt-3">
                  <p className="uselinks"><a href="/terms-of-use">Terms of Use</a> | <a href="/privacy-policy">Privacy Policy</a></p>
            </div>
          </div>
          <div className="copyright">
            {/* Copyright  */}
            <hr/>
            <p className="py-2">Copyright © Humans Softtech, Ahmedabad, Gujarat, India. All Rights Reserved.</p>
          </div>
        
      </div>
    </>
  );
}
