import React from "react";
import "../../styles/footer/Footer.css";

export default function FormFooterTheme() {
  const link = "/thanks";
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [linkedin, setLinkedIn] = React.useState("");
  const [skype, setSkype] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [loader,setLoader] = React.useState(false)
  
  function handleSubmit(event) {
    event.preventDefault();
    setLoader(true);
    console.log(name,email);
    var payload = {
      name: name,
      email: email,
      phone: phone,
      linkedin: linkedin,
      skype: skype,
      country: country,
      message: message
  };
  
  var data = JSON.stringify(payload)
  console.log(data);
    fetch("http://3.110.160.181:8000/contact", { method: "POST",headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    }, body: data })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        setLoader(false);
        if (response.statusCode === 200) {
          
          window.location.href = link;
        } else {
          alert("Unable to send email");
        }
      });
  }
  return (
    <>
      <div className="container-fluid themfootbg px-4 px-md-5">
        <div className="row mobile-form pb-5 pt-5">
          <div className="col-lg-5 footform-mobile d-flex">
            <div className="test-div">
              <h1>
                Get in <br />
                <span className="foottouch">Touch</span>
              </h1>
              
              <p className="humanssofttech pt-2">Humans Softtech</p>
              <p className="office">
                <img src="./assets/footicons/india.png" alt="India" className="img-fluid flags me-3" width={"50px"}/>
                <span className="flaglabel"> India, South Asia</span>
              </p>
              <p className="officeadd">
                Office Number 508, 5th Floor, <br />
                Fortune Business Hub, Near <br />
                Shell Petrol Pump, Science City, <br />
                Ahmedabad 380060, Gujarat, India
                <p className="office">
                  Call:
                  <a
                    href="tel:+916355104070"
                    className="text-decoration-none ms-2"
                    style={{ color: "#001AFF" }}
                  >
                  +91-6355104070
                  </a>
                </p>
              </p>

              <p className="office pt-2">
                <img src="./assets/footicons/usa.png" alt="United States" className="img-fluid flags me-3" width={"50px"}/>
                  <span className="flaglabel"> US, North America</span>
              </p>
              <p className="officeadd">
                9727 SW Ashwood St, Portland,<br/>
                Oregon 97223, United States
                <p className="office">
                  Call:
                  <a
                    href="tel:+13478911823"
                    className="text-decoration-none ms-2"
                    style={{ color: "#001AFF" }}
                  >
                  +1-3478911823
                  </a>
              </p>
              </p>

              <div className="row social">
                <div className="d-flex align-items-center">
                  <div className="col-2 email">
                    <span>
                      <img src="./assets/footicons/email.png" width={"30px"} alt="Email" />
                    </span>
                  </div>

                  <div className="col-2">
                    <span className="footlabel">Email</span>
                  </div>

                  <div className="col-2">
                    <span className="links">
                      <a
                        href="mailto:info@humanssofttech.com"
                        className="text-decoration-none ms-2"
                        style={{ color: "#001AFF" }}
                      >
                        info@humanssofttech.com
                      </a>
                    </span>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="d-flex align-items-center">
                  <div className="col-2 skype">
                    <span>
                      &nbsp;
                      <img src="./assets/footicons/skype.png" width={"24px"} alt="Skype"/>
                    </span>
                  </div>

                  <div className="col-2">
                    <span className="footlabel">Skype</span>
                  </div>

                  <div className="col-2">
                    <span className="links">
                      <a
                        href="https://join.skype.com/invite/yXaKMKj0vFoL"
                        className="text-decoration-none ms-2"
                        style={{ color: "#001AFF" }}
                      >
                        HumansSofttech
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-7 footform-mobile">
            <form className="footform" action="https://formsubmit.co/info@humanssofttech.com" method="post">
            <input type="hidden" name="_subject" value="Contact at Humans Softtech"/>
                        <input type="hidden" name="_next" value="https://www.humanssofttech.com/thanks"/>
                        <input type="hidden" name="_template" value="table"/>
                        <input type="hidden" name="_captcha" value="false"/>
              <h3>Contact Humans Softtech</h3>

              <div className="allrow">
                <div className="form-row d-flex justify-content-evenly">
                  <div className="form-group col-5">
                    <input
                      type="text"
                      className="form-control"
                      id="inputName"
                      placeholder="Name*"
                      autoComplete="off"
                      name="Name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      required
                    />
                  </div>

                  <div className="form-group col-5">
                    <input
                      type="email"
                      className="form-control"
                      id="inputEmail4"
                      placeholder="Email*"
                      autoComplete="off"
                      name="Email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      required
                    />
                  </div>
                </div>

                <div className="form-row d-flex mt-4 justify-content-evenly">
                  <div className="form-group col-5">
                    <input
                      type="tel"
                      className="form-control"
                      id="inputPhone"
                      placeholder="Phone Number*"
                      autoComplete="off"
                      name="Phone Number"
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                      required
                    />
                  </div>

                  <div className="form-group col-5">
                    <input
                      type="url"
                      className="form-control"
                      id="inputLinkedIn"
                      name="LinkedIn"
                      placeholder="LinkedIn Profile*"
                      autoComplete="off"
                      value={linkedin}
                      onChange={(e) => {
                        setLinkedIn(e.target.value);
                      }}
                      required
                    />
                  </div>
                </div>

                <div className="form-row d-flex mt-4 justify-content-evenly">
                  <div className="form-group col-5">
                    <input
                      type="text"
                      className="form-control"
                      id="inputSkype"
                      name="Skype"
                      placeholder="Skype"
                      value={skype}
                      onChange={(e) => {
                        setSkype(e.target.value);
                      }}
                      autoComplete="off"
                    />
                  </div>

                  <div className="form-group col-5">
                    <input
                      type="text"
                      className="form-control"
                      id="inputCountry"
                      name="Country"
                      placeholder="Country*"
                      autoComplete="off"
                      value={country}
                      onChange={(e) => {
                        setCountry(e.target.value);
                      }}
                      required
                    />
                  </div>
                </div>

                <div className="form-row d-flex mt-4 justify-content-evenly">
                  <div className="form-group col-9">
                    <textarea
                      className="form-control"
                      id="inputMessage"
                      rows="3"
                      name="Message"
                      placeholder="Message*"
                      autoComplete="off"
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className="form-group col-1"></div>
                </div>

                <div className="form-row d-flex ms-lg-4 ms-md-4 mt-md-0 mt-0">
                  <div className="form-group justify-content-evenly">
                    <div className="form-check mt-4 checkbtn">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="gridCheck"
                        required
                      />
                      <label className="form-check-label" htmlFor="gridCheck">
                        I accept the privacy policy, receive updates, and
                        consent to sharing my information.
                      </label>
                    </div>

                    <div className="text-center subbtn">
                      <button
                        type="submit"
                        className="btn mt-4 fs-4 bg-primary footbutton checkbtn"
                        disabled={loader}
                      >
                        Submit
                        {loader?<div className="spinner-border spinner-border-sm ms-1 mb-1" id="spinner" role="status"></div>:<></>}
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
