import NavBar from '../components/NavBar';
import ServicesHero from '../components/services/ServicesHero';
import FooterTheme from '../components/footer/FooterTheme';
import React from 'react'
import AllContent from '../components/services/AllContent';
import MetaTags from 'react-meta-tags';

function Services() {
  return (
    <div>
        <MetaTags>
              <title>Custom Website, Mobile App, Ecommerce, Mobile Game, Software, Product, UI/UX Design and Development Services - Humans Softtech</title>
              <meta name="description" content="Humans Softtech is the top Custom Website, Mobile App, Ecommerce, Mobile Game, Software, Product, UI/UX Design & Development, Technologies Consulting Services and Business Solutions Providing Company in Ahmedabad, Gujarat, India." />
              <meta property="og:title" content="Custom Website, Mobile App, Ecommerce, Mobile Game, Software, Product, UI/UX Design and Development Services - Humans Softtech" />
              <meta property="og:image" content="./assets/homeimages/Humans.png" />
        </MetaTags>
        <NavBar/>
        <ServicesHero/>  
        <AllContent/> 
        <FooterTheme/>
    </div>
  )
}

export default Services