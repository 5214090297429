import { Input } from "@mui/material";
import React from "react";
import "../../styles/career/CareerFooter.css";
import {useState, useRef} from 'react';
import axios from 'axios';
//This is testing

function Form() {


  const [file , setFile] = useState({name:"No file chosen, yet."})

  const link = "/thanks";
  const [cname, setcName] = React.useState("");
  const [cemail, setcEmail] = React.useState("");
  const [cphone, setcPhone] = React.useState("");
  const [clinkedin, setcLinkedIn] = React.useState("");
  const [cjobrole, setcJobRole] = React.useState("");
  const [cattach, setcAttach] = React.useState({name:"No file chosen, yet."});
  const [loader,setLoader] = React.useState(false)

  
  function handleSubmit(event) {
    event.preventDefault();
    setLoader(true);
    if(cattach===undefined||cattach===null){
      alert('file not attached');
      return;
    }
    // console.log(name,email);
    const config = {
      headers: {
          "Content-Type":"multipart/form-data" 
      }
  };

  var data = new FormData();
  data.append('file',cattach,cattach.name);
  data.append('cname',cname);
  data.append('cemail',cemail);
  data.append('cphone',cphone);
  data.append('clinkedin',clinkedin);
  data.append('cjobrole',cjobrole);
  console.log(data.file);
    axios.post('https://3.110.160.181:8000/careers',data,config).then(response=>{
      setLoader(false);
      if(response.data.statusCode===200){
        window.location.href="/thanks"
      }else{
        alert("Unable to submit.")
      }
    });
  //   fetch("/career", { method: "POST",headers: {
  //     'Accept': 'application/json, text/plain, */*',
  //     'Content-Type': 'application/json'
  //   }, body: data })
  //     .then((res) => res.json())
  //     .then((response) => {
  //       console.log(response);
  //       setLoader(false);

  //       if (response.statusCode === 200) {
          
  //         window.location.href = link;
  //       } else {
  //         alert("Unable to send email");
  //       }
  //     });
  // }
  }

  return (
    <>  
    <div className="row">
        <div className="col-12">
          <div className="empty" id="empty">

          </div>
        </div>
        </div>
    
      <div className="container-fluid px-4 px-md-5 newbg"  >
        <div className="row mobile-form pb-5 pt-5 my-5" id="formpage">
          
        <div className="col-lg-5 footform-mobile d-flex">
            <div className="test-div">
              <h1>
                Get in <br />
                <span className="foottouch">Touch</span>
              </h1>
              
              <p className="humanssofttech pt-2">Humans Softtech</p>
              <p className="office">
                <img src="./assets/footicons/india.png" alt="India" className="img-fluid flags me-3" width={"50px"}/>
                <span className="flaglabel"> India, South Asia</span>
              </p>
              <p className="officeadd">
                Office Number 508, 5th Floor, <br />
                Fortune Business Hub, Near <br />
                Shell Petrol Pump, Science City, <br />
                Ahmedabad 380060, Gujarat, India
                <p className="office">
                  Call:
                  <a
                    href="tel:+916355104070"
                    className="text-decoration-none ms-2"
                    style={{ color: "#001AFF" }}
                  >
                  +91-6355104070
                  </a>
                </p>
              </p>

              <p className="office pt-2">
                <img src="./assets/footicons/usa.png" alt="United States" className="img-fluid flags me-3" width={"50px"}/>
                  <span className="flaglabel"> US, North America</span>
              </p>
              <p className="officeadd">
                9727 SW Ashwood St, Portland,<br/>
                Oregon 97223, United States
                <p className="office">
                  Call:
                  <a
                    href="tel:+13478911823"
                    className="text-decoration-none ms-2"
                    style={{ color: "#001AFF" }}
                  >
                  +1-3478911823
                  </a>
              </p>
              </p>

              <div className="row social">
                <div className="d-flex align-items-center">
                  <div className="col-2 email">
                    <span>
                      <img src="./assets/footicons/email.png" width={"30px"} alt="Email" />
                    </span>
                  </div>

                  <div className="col-2">
                    <span className="footlabel">Email</span>
                  </div>

                  <div className="col-2">
                    <span className="links">
                      <a
                        href="mailto:humanssofttech@gmail.com"
                        className="text-decoration-none ms-2"
                        style={{ color: "#001AFF" }}
                      >
                        humanssofttech@gmail.com
                      </a>
                    </span>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="d-flex align-items-center">
                  <div className="col-2 skype">
                    <span>
                      &nbsp;
                      <img src="./assets/footicons/skype.png" width={"24px"} alt="Skype"/>
                    </span>
                  </div>

                  <div className="col-2">
                    <span className="footlabel">Skype</span>
                  </div>

                  <div className="col-2">
                    <span className="links">
                      <a
                        href="https://join.skype.com/invite/yXaKMKj0vFoL"
                        className="text-decoration-none ms-2"
                        style={{ color: "#001AFF" }}
                      >
                        HumansSofttech
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-7 footform-mobile">
            <form className="footformnew" action="https://formsubmit.co/humanssofttech@gmail.com" method="post" encType="multipart/form-data">
            <input type="hidden" name="_subject" value="Careers at Humans Softtech"/>

            <input type="hidden" name="_next" value="https://www.humanssofttech.com/thanks"/>
                        <input type="hidden" name="_template" value="table"/>
                        <input type="hidden" name="_captcha" value="false"/>
              <h3>Apply for the Job at Humans Softtech</h3>
              
              <div className="allrow">
                <div className="form-row d-flex justify-content-evenly">
                  
                  <div className="form-group col-5">
                    <input
                      type="text"
                      className="form-control"
                      id="inputName"
                      name="Name"
                      placeholder="Name*"
                      value={cname}
                      onChange={(e) => {
                        setcName(e.target.value);
                      }}
                      required
                    />
                  </div>
                  
                  <div className="form-group col-5">
                    <input
                      type="email"
                      className="form-control"
                      name="Email"
                      id="inputEmail4"
                      placeholder="Email*"
                      value={cemail}
                      onChange={(e) => {
                        setcEmail(e.target.value);
                      }}
                      required
                    />
                  </div>

                </div>

                <div className="form-row d-flex mt-4 justify-content-evenly">
                  <div className="form-group col-5">
                    <input
                      type="tel"
                      className="form-control"
                      name="Phone Number"
                      id="inputPhone"
                      placeholder="Phone Number*"
                      value={cphone}
                      onChange={(e) => {
                        setcPhone(e.target.value);
                      }}
                      required
                    />
                  </div>

                  <div className="form-group col-5">
                    <input
                      type="url"
                      className="form-control"
                      name="LinkedIn"
                      id="inputLinkedIn"
                      placeholder="LinkedIn Profile*"
                      value={clinkedin}
                      onChange={(e) => {
                        setcLinkedIn(e.target.value);
                      }}
                      required
                    />
                  </div>
                </div>

                <div className="form-row d-flex mt-4 justify-content-evenly">
                  <div className="form-group  col-11" style={{maxWidth:"89%"}}>
                    <input
                      type="text"
                      className="form-control"
                      name="Job role"
                      id="inputjobrole"
                      placeholder="Job Role*"
                      value={cjobrole}
                      onChange={(e) => {
                        setcJobRole(e.target.value);
                      }}
                      required
                    />
                  </div>
                
                </div>

                <div className="form-row d-flex  mt-4 justify-content-evenly">
                  <div className=" form-group   col-9">
                  <label className="formnew  ps-2 pe-md-5">
                  <input 
                  type="file"
                  accept=".doc, .docx, .pdf"
                  className="diff-one"
                  name="Attachment"
                  // onChange={(v)=>{setFile(v.target.files[0]);
                  // }} 
                  // name="myFile"
                  id="myFile real-file"
                  hidden="hidden"
                  onChange={(e) => {
                    setcAttach(e.target.files[0]);
                    console.log(e.target.files[0]);
                  }}
                  required
                  / >

                  Attach Resume / CV*
                  </label><span className="custom-text spannew">&nbsp;{cattach?.name?cattach.name:'failed to upload'}</span> 
                      
                 </div>                     
               
                  <div className=" form-group col-1">

                   </div>
                </div>

                <div className="form-row d-flex ms-lg-4 ms-md-4 mt-md-0 mt-0  ">
                <div className="form-group justify-content-evenly">
                    <div className="form-check  mt-4 ms-lg-2  checkbtn newcheckbtn">
                        <input
                        className="form-check-input "
                        type="checkbox"
                        id="gridCheck"
                        required
                        />
                        <label className="form-check-labelnew" for="gridCheck">
                        I accept the privacy policy, receive updates, and consent to the processing of my data.
                        </label>
                    </div>

                    <div className="text-center subbtn">
                        <button
                        type="submit"
                        className="btn mt-4 fs-4 bg-primary careerfootbutton checkbtn"
                        disabled={loader}

                        >
                      Submit
                      {loader?<div className="spinner-border spinner-border-sm ms-1 mb-1" id="spinner" role="status"></div>:<></>}

                    </button>
                  </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        </div>
        
        <div className="container-fluid px-4 px-md-5 py-2 footnewbg">
        <div className="">
        <div className="kartik2 d-flex justify-content-between">
            <div className="d-flex ">

                    <div className="usefullinks">
                        <p className="fw-bold">Useful Links</p>
                    </div>

                    <div className="arrow align-self-start">
                        <img src="./assets/footarrow.png" alt="Arrow" height={"10px"} width={"8px"} className="footlink"/>
                    </div>
            
                    <div className="uselinks">
                        <div className="d-flex grid-container justify-space-end">

                                <div className="footmenu"><a href="services">Services</a></div>                 
                                <div className="footmenu"><a href="technologies">Technologies</a></div>
                                <div className="footmenu"><a href="projects">Projects</a></div>
                                <div className="footmenu"><a href="about">About</a></div>
                                <div className="footmenu"><a href="careers">Careers</a></div>
                                <div className="footmenu"><a href="contact">Contact</a></div>
                        </div>
                    </div>
 
            </div>

            <div className="terms">
                <p className="uselinks"><a href="/terms-of-use">Terms of Use</a> | <a href="/privacy-policy">Privacy Policy</a></p>
            </div>
            </div>
        </div>

          <div className="mt-4">
          <div className="stay-tuned d-flex justify-content-between">
            <div className="d-flex">
                <div className="stay my-1">
                    <p className="fw-bold">Stay Tuned</p>
                </div>

                <div className="arrow my-1 mx-2 align-self-start">
                    <img src="./assets/footarrow.png" height={"10px"} width={"8px"} alt="arrow" className="footlink"/>
                </div>


          <div className="staylogos">
                    <div className="d-flex img-container justify-space-end">

                        <div className="footlink"> <a href="https://www.linkedin.com/company/humans-softtech/"><img src="./assets/footicons/linkedin.png" width={"40px"} alt="LinkedIn"  /></a> </div>
                        <div className="footlink"> <a href="https://www.facebook.com/humanssofttech"><img src="./assets/footicons/facebook.png" width={"40px"} alt="Facebook" /></a> </div>
                        <div className="footlink"> <a href="https://www.instagram.com/humanssofttech/"><img src="./assets/footicons/instagram.png" width={"40px"} alt="Instagram" /></a> </div>
                        <div className="footlink"> <a href="https://twitter.com/humanssofttech"><img src="./assets/footicons/twitter.png" width={"40px"} alt="Twitter" /></a></div>
                        <div className="footlink"> <a href="https://www.youtube.com/@humanssofttech"><img src="./assets/footicons/youtube.png" width={"40px"} alt="YouTube" /></a></div>     
                        <div className="footlink"> <a href="#"><img src="./assets/footicons/github.png" width={"34px"} alt="GitHub" /></a></div>
                        <div className="footlink"> <a href="#"><img src="./assets/footicons/dribble.png" width={"34px"} alt="Dribbble" /></a></div>
                        <div className="footlink"> <a href="#"><img src="./assets/footicons/behance.png" width={"36px"} alt="Behance" /></a></div>               
          
                    </div>    
              </div>  




          </div>
          
          </div>
            <div className="jugaad mt-3">
                  <p className="uselinks"><a href="/terms-of-use">Terms of Use</a> | <a href="/privacy-policy">Privacy policy</a></p>
            </div>
          </div>
          <div className="copyright">
            {/* Copyright  */}
            <hr/>
            <p className="py-2">Copyright © Humans Softtech, Ahmedabad, Gujarat, India. All Rights Reserved.</p>
          </div>
        
      </div>
    </>
  );
 }


export default  Form
