import React, {useState} from 'react'
import "../../styles/Hero.css";
import "@fontsource/open-sans"; // Defaults to weight 400.
import Strings from "../../utils/strings/HomeStrings";

export default function TechWeWork() {
    const [currentSection, setCurrentSection] = useState(
        Strings.techweworkwith[0].pics
      );
      const [currentHeading, setCurrentHeading] = useState(
        Strings.techweworkwith[0].name
      );
  return (
    <>
        {/* TECHNOLOGIES WE WORK WITH */}

      <div className="container-fluid px-4 px-md-5 techwework">
        <div className="row pt-3 pt-lg-5 pt-md-4">
          <div className="col-12 col-md-6 col-lg-6 align-self-end">
            <h3 className="headcss">Technologies We Work With</h3>
          </div>
          <div className="col-6 viewtechbut">
            <div className="d-flex justify-content-end">
              <a href="/technologies" className="text-decoration-none">
                <button
                  type="button"
                  className="btn btn-lg btn-primary viewtech remove-default-bg"
                >
                  {" "}
                  View All Technologies{" "}
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className="row mt-3 mt-md-4 mt-md-5">
          <div className="col-12">
            <div className="techmenu">
              {Strings.techweworkwith.map((item, index) => (
                <button
                  className={
                    "btn pe-lg-5 pb-4 " +
                    (item.name === currentHeading ? "fw-bold" : "")
                  }
                  onClick={() => {
                    setCurrentSection(item.pics);
                    setCurrentHeading(item.name);
                  }}
                >
                  {item.name}
                </button>
              ))}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 mt-lg-5 mt-md-3 mt-5">
            <div className="pb-lg-5 pb-md-4 mt-lg-5 mt-md-5 d-flex justify-content-center text-center">
              {currentSection.map((item, index) => (
                <figure className="figure me-lg-5 me-md-3 me-2">
                  <img
                    src={item.image}
                    width={"80px"}
                    className="figure-img text-center mx-auto img-fluid"
                    alt={item.caption}
                  />
                  <figcaption className="figure-caption text-center mx-auto mt-lg-3 mt-md-2 mt-1">
                    {item.caption}
                  </figcaption>
                </figure>
              ))}

              <div className="iconsmob">
                <div className="row d-flex imgs text-center">
                  {currentSection.map((item, index) => (
                    <div className="col-4 pe-3 pb-4 mx-auto" key={index}>
                      <div className="">
                        <img
                          src={item.image}
                          maxwidth={"80px"}
                          className=" align-items-center "
                          alt={item.caption}
                        />
                      </div>

                      <div className="mt-lg-3 mt-md-2 mt-1">{item.caption}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 viewtechjugaad pt-3 pt-lg-5 pt-md-4 pb-4">
          <div className="d-flex justify-content-center">
            <a href="/technologies" className="text-decoration-none">
              <button type="button" className="btn btn-primary viewtech remove-default-bg">
                {" "}
                View All Technologies{" "}
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="scrollbar cute_scroll">
        <div className="force-overflow"></div>
      </div>

    </>
  )
}
