// import {BrowserRouter, Routes,Route, useLocation} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import React, { useEffect } from 'react'
import "../../styles/project/Main.css";
import ProjectStrings from "../../utils/strings/ProjectStrings";

function Section() {

  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change


  return (
    <> 
      <div className='mb-5'>
        <div className="project-hero ">
          <div className="d-flex color-overlay text-white justify-content-center align-items-center">
            <h1>
              Explore Our Creative
             <br/>
              Digital Transformation Works
            </h1>
          </div>
        </div>

        {ProjectStrings.project.map((item, index) => (
          <div className="h-auto mx-auto container-fluid mt-5 px-4 px-md-5" id={item.id}>
            <div
              style={{ backgroundColor: item.bgColor }}
              className="bgBorderRadius"
            >
              <div>
                <h1 className="cowboy text-center ">{item.heading}</h1>
              </div>
              <div className="row align-items-center mt-lg-4">
                <div className="col-lg-5 text-center col-12">
                  <img
                    src={item.imgURL}
                    className="img-fluid ps-lg-3 image1 "
                    alt={item.heading}
                     
                  />
                </div>
                <div className="col-12 col-lg-7 p-3">
                  <p className="text2   ms-lg-5">{item.line1}</p>
                  <p className="text3 ms-lg-5 mt-lg-4">{item.boldText}</p>
                  <p>
                    <img
                      src="./assets/projectimage/projectStar.png"
                      className="img-fluid star mb-lg-4 ms-lg-5 "
                      alt="star"
                    />
                  </p>
                  <div className="d-flex vector mt-4">
                    <div>
                      <img src="./assets/projectimage/Vector.png" alt="pic" className="vc" />
                    </div>
                    <div className="text4 ms-lg-2 ">{item.review}</div>
                  </div>
                  <div className="d-flex mt-4 ">
                    <div className="vector1 ms-lg-4 mt-lg-4 ">
                      <img className="my-auto  vc1"
                        src="./assets/projectimage/Vector1.png"
                        alt="vector1"
                      />
                    </div>
                    <div className="text5  ms-lg-3 mt-lg-3 ">{item.author}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Section;
