import React from 'react'
import "../../styles/termsofuse/Terms.css"

export default function TermsHero() {
  return (
    <>
        <div className="terms-hero">
          <div className="d-flex color-overlay text-white justify-content-center align-items-center">
            <h1>Terms Of Use</h1>
          </div>
        </div>
 
      <div className="container-fluid px-4 px-md-5">
        <div className="row">
          <div className="col-12 mt-5">
            <p className='allfont'>Last updated: September 19, 2022</p>
            <p className='allfont'>Please read these terms and conditions carefully before using our website.</p>
     
     
       <h4 className='allhead mt-3'>Interpretation and Definitions</h4>

       <h4 className='allhead mt-3'>Interpretation</h4>
            <p className='allfont'>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
    
       <h4 className='allhead mt-3'>Definitions</h4>
            <p className='allfont'>For the purposes of these Terms and Conditions:</p>
              <div className='d-flex'> 
                <div className=''><img src='./assets/projectimage/Vector1.png' className='termsvc' alt="hyphen"/> </div>
                <div className=' ms-3'>  Affiliate means an entity that controls, is controlled by or is under 
                common control with a party, where "control" means ownership of 50% or more of the shares, 
                equity interest or other securities entitled to vote for election of directors or other managing
                 authority.
                </div>
              </div>

              <div className='d-flex'> 
                <div className=''><img src='./assets/projectimage/Vector1.png' className='termsvc' alt="hyphen"/> </div>
                <div className='ms-3'>  Country refers to: India
                </div>
              </div>

              <div className='d-flex'> 
                <div className=''><img src='./assets/projectimage/Vector1.png' className='termsvc' alt="hyphen" /> </div>
                <div className='ms-3'>  Company (referred to as either "the Company", "We", "Us" or "Our" in 
                this Agreement) refers to Humans Softtech, Office Number 508, 5th Floor, Fortune Business Hub,
                 Near Shell Petrol Pump, Science City, Ahmedabad 380060, Gujarat, India.
                </div>
              </div>

              <div className='d-flex'> 
                <div className=''><img src='./assets/projectimage/Vector1.png' className='termsvc' alt="hyphen" /> </div>
                <div className='ms-3'>  Device means any device that can access the website such as a computer,
                 a cell phone or a digital tablet.

                </div>
              </div>

              <div className='d-flex'> 
                <div className=''><img src='./assets/projectimage/Vector1.png' className='termsvc' alt="hyphen" /> </div>
                <div className='ms-3'>  Service refers to the Website.
                </div>
              </div> 

              <div className='d-flex'> 
                <div className=''><img src='./assets/projectimage/Vector1.png' className='termsvc' alt="hyphen" /> </div>
                <div className='ms-3'>  Terms and Conditions (also referred as "Terms") mean these Terms and Conditions
                 that form the entire agreement between You and the Company regarding the use of the Service.
                </div>
              </div>

              <div className='d-flex'> 
                <div className=''><img src='./assets/projectimage/Vector1.png' className='termsvc' alt="hyphen"/> </div>
                <div className='ms-3'> Third-party Social Media Service means any services or content
                 (including data, information, products or services) provided by a third-party that may be
                  displayed, included or made available by the Service.
                </div>
              </div>

              <div className='d-flex'> 
                <div className=''><img src='./assets/projectimage/Vector1.png' className='termsvc' alt="hyphen" /> </div>
                <div className='ms-3'>Website refers to Humans Softtech, accessible from <a href="https://www.humanssofttech.com"> <span style={{color:"#001AFF"}}> https://www.humanssofttech.com </span></a>
                </div>
              </div>

              <div className='d-flex'> 
                <div className=''><img src='./assets/projectimage/Vector1.png' className='termsvc' alt="hyphen" /> </div>
                <div className='ms-3'> You mean the individual accessing or using the Service, or the company,
                 or other legal entity on behalf of which such individual is accessing or using the Service, as
                  applicable.
                </div>
              </div>
         </div>

         <h4 className='allhead mt-3'>Acknowledgment</h4>
            <p className='allfont mt-2'>These are the Terms and Conditions governing the use of this Service and the 
            agreement that operates between You and the Company. These Terms and Conditions set out the rights
             and obligations of all users regarding the use of the Service.</p>
             <p className='allfont mt-1'>Your access to and use of the Service is conditioned on Your acceptance of
              and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors,
               users and others who access or use the Service.</p> <br />
             <p className='allfont mt-1'> By accessing or using the Service You agree to be bound by these Terms and 
             Conditions. If You disagree with any part of these Terms and Conditions then You may not access the 
             Service.</p> <br />
             <p className='allfont mt-1'> You represent that you are over the age of 18. The Company does not permit
              those under 18 to use the Service.</p>
             <p className='allfont mt-1'>Your access to and use of the Service is also conditioned on Your acceptance 
             of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies
              and procedures on the collection, use and disclosure of Your personal information when You use the 
              Application or the Website and tells You about Your privacy rights and how the law protects You.
               Please read Our Privacy Policy carefully before using Our Service.</p>

           <h4 className='allhead mt-3'>Links to Other Websites</h4>
             <p className='allfont mt-2'>Our Service may contain links to third-party web sites or services that are
              not owned or controlled by the Company.</p>
             <p className='allfont mt-1'>The Company has no control over, and assumes no responsibility for, the content, 
             privacy policies, or practices of any third party web sites or services. You further acknowledge and agree 
             that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused
              or alleged to be caused by or in connection with the use of or reliance on any such content, goods or 
              services available on or through any such web sites or services.</p>
             <p className='allfont mt-1'>We strongly advise You to read the terms and conditions and privacy policies of any 
             third-party web sites or services that You visit.</p>
          
          <h4 className='allhead mt-3'>Termination</h4>
             <p className='allfont mt-2'>We may terminate or suspend Your access immediately, without prior notice or 
             liability, for any reason whatsoever, including without limitation if You breach these Terms and 
             Conditions.</p>
             <p className='allfont mt-1'>Upon termination, Your right to use the Service will cease immediately.</p>
         
          <h4 className='allhead mt-3'>Limitation of Liability</h4>
             <p className='allfont mt-2'>To the maximum extent permitted by applicable law, in no event shall the
              Company or its suppliers be liable for any special, incidental, indirect, or consequential damages
               whatsoever (including, but not limited to, damages for loss of profits, loss of data or other 
               information, for business interruption, for personal injury, loss of privacy arising out of or
                in any way related to the use of or inability to use the Service, third-party software and/or
                 third-party hardware used with the Service, or otherwise in connection with any provision of
                  this Terms), even if the Company or any supplier has been advised of the possibility of such
                   damages and even if the remedy fails of its essential purpose.</p>
             <p className='allfont mt-1'>Some states do not allow the exclusion of implied warranties or limitation 
             of liability for incidental or consequential damages, which means that some of the above limitations
             may not apply. In these states, each party's liability will be limited to the greatest extent 
             permitted by law.</p>

          <h4 className='allhead mt-3'>"AS IS" and "AS AVAILABLE" Disclaimer</h4>
             <p className='allfont mt-2'>The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults
             and defects without warranty of any kind. To the maximum extent permitted under applicable law, the
             Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors 
             and service providers, expressly disclaims all warranties, whether express, implied, statutory or 
             otherwise, with respect to the Service, including all implied warranties of merchantability, fitness
             for a particular purpose, title and non-infringement, and warranties that may arise out of course 
             of dealing, course of performance, usage or trade practice. Without limitation to the foregoing,
             the Company provides no warranty or undertaking, and makes no representation of any kind that the
             Service will meet Your requirements, achieve any intended results, be compatible or work with any
             other software, applications, systems or services, operate without interruption, meet any
             performance or reliability standards or be error free or that any errors or defects can or will be
              corrected.</p>
             <p className='allfont mt-1'>Without limiting the foregoing, neither the Company nor any of the company's
              provider makes any representation or warranty of any kind, express or implied: (i) as to the operation 
              or availability of the Service, or the information, content, and materials or products included
               thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy,
                reliability, or currency of any information or content provided through the Service; or (iv) 
                that the Service, its servers, the content, or e-mails sent from or on behalf of the Company
                 are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful
                  components.</p>
             <p className='allfont mt-1'>Some jurisdictions do not allow the exclusion of certain types of warranties
              or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions
               and limitations may not apply to You. But in such a case the exclusions and limitations set forth
                in this section shall be applied to the greatest extent enforceable under applicable law.</p>
               
           <h4 className='allhead mt-3'>Governing Law</h4>
             <p className='allfont mt-2'>The laws of the Country, excluding its conflicts of law rules, shall govern 
             this Terms and Your use of the Service. Your use of the Application may also be subject to other 
             local, state, national, or international laws.</p>

           <h4 className='allhead mt-3'>Disputes Resolution</h4>
             <p className='allfont mt-2'>If You have any concern or dispute about the Service, You agree to first
              try to resolve the dispute informally by contacting the Company.</p>

          <h4 className='allhead mt-3'>For European Union (EU) Users</h4>
             <p className='allfont mt-2'>If You are a European Union consumer, you will benefit from any mandatory
              provisions of the law of the country in which you are resident in.</p>

          <h4 className='allhead mt-3'>United States Legal Compliance</h4>
             <p className='allfont mt-2'>You represent and warrant that (i) You are not located in a country that
              is subject to the United States government embargo, or that has been designated by the United 
              States government as a "terrorist supporting" country, and (ii) You are not listed on any United
               States government list of prohibited or restricted parties.</p>

          <h4 className='allhead mt-3'>Severability and Waiver</h4>
             <h4 className='allhead mt-3'>Severability</h4>
             <p className='allfont mt-2'>If any provision of these Terms is held to be unenforceable or invalid, 
             such provision will be changed and interpreted to accomplish the objectives of such provision to 
             the greatest extent possible under applicable law and the remaining provisions will continue in 
             full force and effect.</p>
             <h4 className='allhead  mt-3'>Waiver</h4>
             <p className='allfont mt-2'>Except as provided herein, the failure to exercise a right or to require
              performance of an obligation under these Terms shall not affect a party's ability to exercise such 
              right or require such performance at any time thereafter nor shall the waiver of a breach 
              constitute a waiver of any subsequent breach.</p>

          <h4 className='allhead mt-3'>Translation Interpretation</h4>
             <p className='allfont mt-2'>These Terms and Conditions may have been translated if We have made them
              available to You on our Service. You agree that the original English text shall prevail in the 
              case of a dispute.</p>

          <h4 className='allhead mt-3'>Changes to These Terms and Conditions</h4>
             <p className='allfont mt-2'>We reserve the right, at Our sole discretion, to modify or replace these 
             Terms at any time. If a revision is material We will make reasonable efforts to provide at least
              30 days' notice prior to any new terms taking effect. What constitutes a material change will 
              be determined at Our sole discretion.</p>
             <p className='allfont mt-1'> By continuing to access or use Our Service after those revisions become 
             effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in
              whole or in part, please stop using the website and the Service.</p>

         <h4 className='allhead mt-3'>Contact Us</h4>
             <p className='allfont mt-2'>If you have any questions about these Terms and Conditions, You can 
             contact us:</p>

             <div className='d-flex mt-1'> 
                <div className=''><img src='./assets/projectimage/Vector1.png' className='termsvc' alt="hyphen" /> </div>
                <div className='ms-3 mb-5'>  By mail/post: Humans Softtech, Office Number 508, 5th Floor,
                 Fortune Business Hub, Near Shell Petrol Pump, Science City, Ahmedabad 380060, Gujarat, India
                </div>
              </div>

            




        </div>
      </div>
    </>
  )
}
