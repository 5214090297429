import "../../styles/About.css";
import AboutStrings from "../../utils/strings/AboutStrings";

function AboutBlock() {
  return (
    <div>
      <div className="container-fluid px-4 px-md-5">
        <div className="about-heading mt-3 py-2 py-md-4">{AboutStrings.allHead.aboutBlock}</div>
      </div>

      <div className="row my-3 justify-content-around container-fluid px-4  px-md-5 ">
        {AboutStrings.aboutSection.map((item, index) => (<>
          <div className="d-block d-md-none boxes mobile-about-block  row mb-3 mb-md-4 mb-lg-5 justify-content-center align-items-center py-4 px-4 px-md-4 px-lg-5 "
            style={{ backgroundColor: item.color, width: "90%",boxShadow:item.boxShadowsmall,}}>
              <div className="about-block-small-screen-text"> {item.boxContent} </div>
          </div>

          <div className="d-none d-md-block boxes row  mb-3 mb-md-4 mb-lg-5   px-2 px-md-4 px-lg-5 "
            style={{ padding:"5rem 0",backgroundColor: item.color, width: "45%", boxShadow:item.boxShadow,}}>
           <div className="about-block-text"> {item.boxContent}</div>
          </div>
          </>))}
      </div>      
    </div>
  );
}

export default AboutBlock;
