import NavBar from '../components/NavBar'
import Error from '../components/Error'
import ThankYouExpertise from '../components/thankyou/ThankYouExpertise';
import LinkFooterTheme from '../components/footer/LinkFooterTheme';
import MetaTags from 'react-meta-tags';

function Error404() {
  return (
    <div>
      <MetaTags>
              <title>404 - Page Not Found - Humans Softtech</title>
              <meta name="description" content="404 - Page Not Found - Humans Softtech" />
              <meta property="og:title" content="404 - Page Not Found - Humans Softtech" />
              <meta property="og:image" content="./assets/homeimages/Humans.png" />
      </MetaTags>
      <NavBar/>
      <Error/>
      <ThankYouExpertise/>
      <LinkFooterTheme/>
    </div>
  )
}

export default Error404