function ScrollSection({ items, flag}) {
  console.log(flag)
    return (
      <div className={"d-flex tech-scroller "+(flag?"":"tech-scroller-white")} style={{ maxWidth: "100%", overflowX: "scroll" }}>

        {items.map((item, index) => (
          <div
            className=" col-sm-6 col-md-4 col-9 col-lg-4 col-xl-3 col-xxl-2 content-container row color-round py-5 me-4 mb-5 scroll-box"
            key={index}
            style={{ backgroundColor: item.color }}
          >
            <div className="text-center">
              <img className="img-scroller pb-3" src={item.link} alt={item.text}/>{" "}
            </div>
            <div className="text-white font-scroller text-center ">{item.text}</div>        
          </div>
        ))}
      </div>
    );
  }

export default ScrollSection;