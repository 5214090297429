import "../../styles/Technologies.css";
import TechStrings from "../../utils/strings/TechnologiesStrings";
import ScrollSection from "./TechScrollSection";

function AllContent({ items }) {
  return (
    <div>
    {TechStrings.allContent.map((item, index) => (
    <div
      key={index}
      className="all-content-container py-md-5"
      style={{
        backgroundColor: item.flag ? "#FFDAA5D6" : "white",
        position: "relative",
      }}
    >
      <div className="container-fluid px-4 px-md-5">
      <div className="content-head fs-1 fw-bold mb-md-5">{item.head}</div>
      <div className="content fs-2">{item.content}</div>
      </div>
      <ScrollSection items={item.tablets} flag={item.flag} />
      <div className="text-end">
        <img
          src="./assets/servicesimages/slider-arrow3.png"
          alt=""
          srcset=""
          className="slider-arrow"
        />
      </div>
    </div>
    ))}
    </div>
  );
}

export default AllContent;
