import React from 'react'
import "../styles/Error404.css"

export default function Error() {
  return (
    <>
    <div>

        <div className="error-hero">
          <div className="d-flex errorcolor-overlay text-white px-4 px-md-5 justify-content-start align-items-center">
            <div className="col-6"><h1>404 Page Not Found </h1></div>
          </div>
        </div>

        <div className='row m-3 m-md-5'>
            <div className='col-12 error-text mt-5 mb-5 text-center'> <h2>Sorry, we can't find that page. You'll find lots to explore on the above and below links.</h2>
            </div>
        </div>

    </div>
    </>
  );
}
