import "../../styles/About.css";
import AboutStrings from "../../utils/strings/AboutStrings";

function Team() {
  return (
    <div className="pb-5" style={{backgroundColor:"#FFDAA5"}}>
        <div className="container-fluid px-4  px-md-5">
            <div className="about-heading my-4 py-2 py-md-5 mb-0">{AboutStrings.allHead.team}</div>

            <div className="text-center">
            <img className="team-image img-fluid" src="./assets/homeimages/humansteam.png"  alt="Humans Softtech Team" />
            </div>
        </div>
    </div>
  )
}

export default Team