import React,{ useState } from 'react'
import "../../styles/Hero.css";
import "@fontsource/open-sans"; // Defaults to weight 400.
import Strings from "../../utils/strings/HomeStrings";

export default function AboutHumans() {
  return (
    <>
    <div className="container-fluid px-4 px-md-5 abouthumans">
        <div className="row pt-4 pb-1 pt-lg-5 pt-md-5 pb-lg-2 pb-md-2">
          <div className="col-6 human align-self-end">
            <h3 className="headcss"> About Humans Softtech </h3>
          </div>

          <div className="col-6 viewaboutbut">
            <div className="d-flex justify-content-end">
              <a href="/about">
                <button
                  type="button"
                  className="btn btn-lg btn-primary viewabout remove-default-bg"
                >
                  {" "}
                  View All About Us{" "}
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className="row pb-4 pt-3 pt-lg-4 pt-md-4 pb-lg-5 pb-md-4">
          <div className="col-12 col-lg-5">
            <div>
              <img
                src="./assets/homeimages/humansteam.png"
                className="img-fluid teamimg"
                alt="Humans Softtech Team"
              />
            </div>
          </div>

          <div className="col-12 col-lg-6 col-md-12 pt-md-5 pt-4 abouttext ps-lg-3 m-auto">
            {Strings.abouthumans.map((item, index) => (
              <p className="gapping">
                <span className="dash">—</span>&nbsp;&nbsp;&nbsp;
                {item.heading}
              </p>
            ))}
          </div>

          <div className="col-12 viewaboutjugaad pt-3">
            <div className="d-flex justify-content-center">
              <a href="/about">
                <button type="button" className="btn btn-primary viewabout remove-default-bg">
                  {" "}
                  View All About Us{" "}
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
