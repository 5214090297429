import React from 'react'
import "../../styles/Hero.css";
import "@fontsource/open-sans"; // Defaults to weight 400.
import Strings from "../../utils/strings/HomeStrings";

export default function WhyDoWeDo() {
  return (
    <>
        <div className="container-fluid px-4 px-md-5 whywedo">
            <div className="row pb-lg-5 pb-md-5 pb-2">
                <div className="col-12 pt-lg-5 pb-lg-4 pt-md-4 pt-3 pb-md-4 ">
                    <p className="headcss"> Why We Do What We Do </p>
                </div>

                <div className="col-lg-6 col-md-6 col-12">
                    <div className="d-flex align-items-center">
                        <img
                            src="./assets/homeimages/Hero2.png"
                            className="img-fluid handshake"
                            width={"550px"}
                            alt="Why We Do What We Do"
                        />
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-12 handshaketext p-xxl-0 p-xl-2 p-lg-3 pt-md-3 ps-md-4 ps-0 pt-3 m-auto">
                    {Strings.whywedo.map((item, index) => (
                        <p key={index}>{item}</p>
                    ))}
                </div>
            </div>
        </div>
    </>
  )
}
