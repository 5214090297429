import NavBar from '../components/NavBar'
import CareerHero from '../components/career/careerHero'
import CareerFooter from '../components/career/careerFooter'
import React from 'react';
import MetaTags from 'react-meta-tags';


function Career() {
  console.log('career')
  return (
    <div>
        <MetaTags>
              <title>Careers at Humans Softtech</title>
              <meta name="description" content="Discover your career opportunities at Humans Softtech." />
              <meta property="og:title" content="Careers at Humans Softtech" />
              <meta property="og:image" content="./assets/homeimages/Humans.png" />
        </MetaTags>
        <NavBar/>
         <CareerHero/>
         <CareerFooter/>
    </div>
  )
}

export default Career