import "../../styles/Technologies.css";

function Hero() {
  return (
    <div>
      <div className="main-hero-technology">
                <div className="d-flex color-overlay text-white justify-content-center align-items-center">
                    <h1>Explore Our Creative<br/>Digital Transformation Technologies</h1>
                </div>
       </div>

      </div>
  );
}
export default Hero;
